/* Slider */
/* Dots */
/* COLUMNS */
/* MENU */
/* Mobile first layout SmartMenus Core CSS (it's not recommended editing these rules)
   You need this once per page no matter how many menu trees or different themes you use.
-------------------------------------------------------------------------------------------*/
/* WP DEFAULT CLASSES */
/* Text meant only for screen readers. */
/*------------------------------------------------------------------
ver 1.1

[Table of contents]
1. Global CSS
        1.1 Global Header Logo/Menu CSS 
2. Blog Page CSS
3. General Page CSS
4. About Page CSS
5. Single Post CSS
6. Archive Page CSS
7. Search Page CSS
8. Portfolio (Home) Page CSS
        8.1 Portfolio Single Post CSS
9. Gallery Page CSS
        9.1 Gallery Single Post CSS
10. Contact Page CSS
11. Footer CSS
12. Responsive CSS
-------------------------------------------------------------------*/
/* ===================================
    1. Global CSS
====================================== */
/* ===================================
    1.1 Global Header Logo/Menu CSS
====================================== */
/* ===================================
    2. Blog Page CSS
====================================== */
/* ===================================
    3. General Page CSS
====================================== */
/* ===================================
    4. About Page CSS
====================================== */
/* ===================================
    5. Single Post CSS
====================================== */
/* ===================================
    6. Archive Page CSS
====================================== */
/* ===================================
    7. Search Page CSS
====================================== */
/* ===================================
    8. Portfolio (Home) Page CSS
====================================== */
/* ===================================
    8.1 Portfolio Single Post CSS
====================================== */
/* ===================================
    9. Gallery Page CSS
====================================== */
/* ===================================
    9.1 Gallery Single Post CSS
====================================== */
/* ===================================
    10. Contact Page CSS
====================================== */
/* ===================================
    11. Footer CSS
====================================== */
/* ===================================
    12. Responsive CSS
====================================== */
html {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  line-height: 1;
  font-family: "Roboto", serif;
  font-size: 17px;
  line-height: 35px;
  font-weight: 400;
  color: #000;
  background-color: #fff; }
  body a {
    text-decoration: none;
    color: #000;
    transition: color .2s linear; }
    body a:hover {
      color: #e6b02f; }

div {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

span {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

object {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

iframe {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  width: 100%; }

h1 {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  padding: 20px 0;
  font-size: 38px;
  line-height: 38px; }

h2 {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  padding: 20px 0;
  font-size: 34px;
  line-height: 34px; }

h3 {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  padding: 20px 0;
  font-size: 32px;
  line-height: 32px; }

h4 {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  padding: 20px 0;
  font-size: 30px; }

h5 {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  padding: 20px 0;
  font-size: 28px; }

h6 {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  padding: 20px 0;
  font-size: 26px; }

p {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

blockquote {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  quotes: none;
  margin-top: 90px;
  margin-bottom: 50px;
  font-size: 24px;
  line-height: 44px;
  color: #000;
  font-weight: 400;
  position: relative;
  z-index: 2; }
  blockquote:before {
    content: '';
    content: none;
    content: "\201C";
    font-family: "Montserrat", sans-serif;
    color: #ffcc00;
    font-size: 250px;
    line-height: 0;
    position: absolute;
    left: -40px;
    z-index: 1;
    font-weight: 700;
    top: 70px; }
  blockquote:after {
    content: '';
    content: none; }
  blockquote p {
    position: relative;
    z-index: 2; }

pre {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  word-wrap: break-word;
  white-space: pre-wrap; }

abbr {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

address {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-size: 32px;
  line-height: 50px;
  font-style: normal; }

cite {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

code {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

del {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: line-through; }

dfn {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

em {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-style: italic; }

img {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

kbd {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

q {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  quotes: none; }
  q:before {
    content: '';
    content: none; }
  q:after {
    content: '';
    content: none; }

samp {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

small {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

strong {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

sub {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  vertical-align: sub;
  font-size: smaller; }

sup {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  vertical-align: super;
  font-size: smaller; }

var {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

b {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

i {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

form {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

label {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

legend {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

caption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

tfoot {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

thead {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

article {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: block; }

aside {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: block; }

canvas {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

details {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: block; }

figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: block; }

figure {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: block; }

footer {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: block; }
  footer ul {
    list-style: none;
    padding-left: 0;
    margin-left: 0; }

header {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: block; }

hgroup {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: block; }

menu {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: block; }

nav {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: block; }

section {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: block; }

summary {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

time {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

mark {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

audio {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none;
  vertical-align: baseline;
  background: transparent; }
  a img.alignright {
    float: right;
    margin: 5px 0 20px 20px; }
  a img.alignnone {
    margin: 20px 0px; }
  a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0; }
  a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto; }

abbr[title] {
  border-bottom: 1px dotted;
  cursor: help; }

dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input {
  vertical-align: middle; }
  input:focus {
    outline: none; }

select {
  vertical-align: middle; }

textarea:focus {
  outline: none; }

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }
  .slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }
  .slick-track:before {
    display: table;
    content: ''; }
  .slick-track:after {
    display: table;
    content: '';
    clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-loading .slick-slide {
  visibility: hidden; }

.gallery-item.slick-slide {
  float: none;
  height: 100%;
  min-height: 1px;
  vertical-align: middle;
  position: relative;
  display: inline-block !important; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }
  .slick-slide img {
    display: block;
    margin: 0 auto; }

.carousel-slider .slick-slide {
  margin: 0 64px;
  opacity: 0.7;
  transition: opacity 0.4s ease; }
  .carousel-slider .slick-slide img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%; }
  .carousel-slider .slick-slide .item-text {
    position: absolute;
    top: 30px;
    left: 30px;
    bottom: 30px;
    right: 30px;
    font-size: 20px;
    background-color: white;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease-out; }
    .carousel-slider .slick-slide .item-text a {
      font-family: "Playfair Display", serif;
      font-weight: 400;
      font-size: 40px;
      line-height: 45px;
      -ms-word-wrap: break-word;
      word-wrap: break-word;
      transition: opacity 0.4s ease, color .2s linear;
      display: block;
      pointer-events: none;
      cursor: default; }

.carousel-slider .slick-slide.slick-center {
  opacity: 1; }
  .carousel-slider .slick-slide.slick-center:hover .item-text {
    opacity: 1; }
  .carousel-slider .slick-slide.slick-center .item-text a {
    pointer-events: auto;
    cursor: auto; }

[dir='rtl'] .slick-slide {
  float: right; }

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto; }
  [dir='rtl'] .slick-prev:before {
    content: '+'; }

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px; }
  [dir='rtl'] .slick-next:before {
    content: '-'; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  margin-top: -10px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 10;
  width: 35px;
  height: 20px;
  opacity: 0.3;
  transition: opacity 0.4s ease;
  background-image: url(../images/opta_arrow@2x_left.png);
  background-repeat: no-repeat;
  background-size: 35px 20px;
  background-color: transparent;
  padding: 35px;
  background-position: center; }
  .slick-prev:hover {
    opacity: 1; }
    .slick-prev:hover:before {
      opacity: 1; }
  .slick-prev:focus:before {
    opacity: 1; }
  .slick-prev:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '-'; }

.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  margin-top: -10px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 10;
  width: 35px;
  height: 20px;
  opacity: 0.3;
  transition: opacity 0.4s ease;
  right: 0;
  background-image: url("../images/opta_arrow@2x.png");
  background-repeat: no-repeat;
  background-size: 35px 20px;
  background-color: transparent;
  padding: 35px;
  background-position: center; }
  .slick-next:hover {
    opacity: 1; }
    .slick-next:hover:before {
      opacity: 1; }
  .slick-next:focus:before {
    opacity: 1; }
  .slick-next:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '+'; }

.slick-prev.slick-disabled:before {
  opacity: .25; }

.slick-next.slick-disabled:before {
  opacity: .25; }

.slick-dotted.slick-slider {
  margin-bottom: 80px; }

.slick-dots {
  position: absolute;
  bottom: -50px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent; }
      .slick-dots li button:hover {
        outline: none; }
        .slick-dots li button:hover:before {
          opacity: 1; }
      .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        font-size: 6px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "";
        border-radius: 90%;
        background-color: red;
        text-align: center;
        opacity: .25;
        color: black;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: #d5d5d5;
        opacity: 1;
        transition: background-color .3s ease, opacity .3s ease; }
    .slick-dots li:hover button:before {
      background-color: #e6b02f;
      opacity: 0.3; }
  .slick-dots li.slick-active button:before {
    opacity: .75;
    color: black;
    background-color: #e6b02f; }

*:focus {
  outline: none; }

.block {
  display: block; }

.hidden {
  display: none; }

.clear {
  clear: both; }

.left {
  float: left; }

.right {
  float: right; }

.line-height {
  line-height: 0; }

.clear-right {
  clear: right; }

.clear-left {
  clear: left; }

.center-relative {
  margin-left: auto;
  margin-right: auto; }

.center-absolute {
  left: 50%; }

.center-text {
  text-align: center !important; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.absolute {
  position: absolute; }

.fixed {
  position: fixed; }

.relative {
  position: relative; }

.margin-0 {
  margin: 0 !important;
  line-height: 0; }
  .margin-0 img {
    margin: 0 !important;
    line-height: 0; }

.top-0 {
  margin-top: 0 !important; }

.top-5 {
  margin-top: 5px !important; }

.top-10 {
  margin-top: 10px; }

.top-15 {
  margin-top: 15px; }

.top-20 {
  margin-top: 20px; }

.top-25 {
  margin-top: 25px; }

.top-30 {
  margin-top: 30px; }

.top-50 {
  margin-top: 50px; }

.top-60 {
  margin-top: 60px; }

.top-70 {
  margin-top: 70px; }

.top-75 {
  margin-top: 75px; }

.top-80 {
  margin-top: 80px; }

.top-100 {
  margin-top: 100px; }

.top-105 {
  margin-top: 105px; }

.top-120 {
  margin-top: 120px; }

.top-150 {
  margin-top: 150px; }

.top-200 {
  margin-top: 200px; }

.top-negative-30 {
  margin-top: -30px; }

.left-35 {
  margin-left: 35px; }

.right-35 {
  margin-right: 35px; }

.bottom-0 {
  margin-bottom: 0 !important; }

.bottom-10 {
  margin-bottom: 10px !important; }

.bottom-20 {
  margin-bottom: 20px !important; }

.bottom-25 {
  margin-bottom: 25px !important; }

.bottom-30 {
  margin-bottom: 30px !important; }

.bottom-50 {
  margin-bottom: 50px !important; }

.bottom-100 {
  margin-bottom: 100px !important; }

.bottom-150 {
  margin-bottom: 150px !important; }

.bottom-200 {
  margin-bottom: 200px !important; }

.width-25 {
  width: 25%; }

.width-50 {
  width: 50%; }

.width-75 {
  width: 75%; }

.width-85 {
  width: 85%; }

.width-100 {
  width: 100%; }

.sticky {
  font-size: 100%; }

blockquote.inline-blockquote {
  width: 480px;
  float: left;
  margin-left: -80px;
  margin-right: 100px;
  margin-top: 70px; }

.wrap-blockquote {
  display: inline; }

a.button {
  display: inline-block;
  color: #f277ae;
  border: 1px solid #f277ae;
  text-align: center;
  padding-bottom: 15px;
  padding-top: 12px;
  padding-left: 50px;
  padding-right: 50px;
  cursor: pointer;
  margin-bottom: 25px;
  vertical-align: middle;
  text-decoration: none;
  border-radius: 50px;
  font-size: 22px;
  transition: all .2s linear; }
  a.button:active {
    position: relative;
    top: 1px; }
  a.button:hover {
    color: white;
    background-color: #f277ae; }

ol {
  list-style-type: decimal; }

table {
  border: 1px solid black;
  padding: 10px;
  vertical-align: middle; }

th {
  border: 1px solid black;
  padding: 10px;
  vertical-align: middle; }

td {
  border: 1px solid black;
  padding: 10px;
  vertical-align: middle; }

.single-content ol {
  display: block;
  list-style-type: decimal;
  margin: 0 0 14px 17px; }

.single-content ul {
  display: block;
  list-style-type: disc;
  margin: 0 0 14px 17px; }

.one {
  margin-right: 14%;
  float: left;
  position: relative;
  margin-bottom: 30px;
  float: none;
  display: block;
  clear: both;
  margin-right: 0; }

.one_half {
  margin-right: 14%;
  float: left;
  position: relative;
  margin-bottom: 30px;
  width: 43%; }

.one_third {
  margin-right: 14%;
  float: left;
  position: relative;
  margin-bottom: 30px;
  width: 24%; }

.two_third {
  margin-right: 14%;
  float: left;
  position: relative;
  margin-bottom: 30px;
  width: 62%; }

.three_fourth {
  margin-right: 14%;
  float: left;
  position: relative;
  margin-bottom: 30px;
  width: 71.5%; }

.one_fourth {
  margin-right: 14%;
  float: left;
  position: relative;
  margin-bottom: 30px;
  width: 14.5%; }

.last {
  margin-right: 0 !important;
  clear: right; }

.sm {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .sm ul {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: normal;
    direction: ltr;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: none; }
  .sm li {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: normal;
    direction: ltr;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: relative; }
  .sm > li > h1 {
    margin: 0;
    padding: 0; }
  .sm > li > h2 {
    margin: 0;
    padding: 0; }
  .sm > li > h3 {
    margin: 0;
    padding: 0; }
  .sm > li > h4 {
    margin: 0;
    padding: 0; }
  .sm > li > h5 {
    margin: 0;
    padding: 0; }
  .sm > li > h6 {
    margin: 0;
    padding: 0; }
  .sm a {
    position: relative;
    display: block; }
  .sm a.disabled {
    cursor: not-allowed; }
  .sm:after {
    content: "\00a0";
    display: block;
    height: 0;
    font: 0px/0 serif;
    clear: both;
    visibility: hidden;
    overflow: hidden; }
  .sm * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
    .sm *:before {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box; }
    .sm *:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box; }

.sm-rtl {
  direction: rtl;
  text-align: right; }
  .sm-rtl ul {
    direction: rtl;
    text-align: right; }
  .sm-rtl li {
    direction: rtl;
    text-align: right; }

.alignnone {
  margin: 5px 20px 20px 0; }

.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
  display: block;
  margin: 5px auto 5px auto; }

div.aligncenter {
  display: block;
  margin: 5px auto 5px auto; }

.alignright {
  float: right;
  margin: 5px 0 20px 20px; }

.alignleft {
  float: left;
  margin: 5px 20px 20px 0; }

.wp-caption {
  max-width: 100%; }
  .wp-caption img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%; }
  .wp-caption .wp-caption-text {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
    font-style: italic;
    text-align: center; }
    .wp-caption .wp-caption-text a {
      font-weight: 700; }

.wp-caption.alignnone {
  margin: 20px 0px; }

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
  text-align: left; }

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
  text-align: right; }

.gallery-caption {
  font-size: 100%; }

.bypostauthor {
  font-size: 100%; }

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden; }
  .screen-reader-text:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    color: #21759b;
    display: block;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000; }

.sm-clean {
  background: #eeeeee;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px; }
  .sm-clean a {
    padding: 10px 20px;
    padding-right: 58px;
    font-size: 18px;
    line-height: 17px;
    text-decoration: none;
    padding-right: 0;
    padding-left: 0; }
    .sm-clean a:hover {
      padding: 10px 20px;
      padding-right: 58px;
      font-size: 18px;
      line-height: 17px;
      text-decoration: none;
      padding-right: 0;
      padding-left: 0; }
    .sm-clean a:focus {
      padding: 10px 20px;
      padding-right: 58px;
      font-size: 18px;
      line-height: 17px;
      text-decoration: none;
      padding-right: 0;
      padding-left: 0; }
    .sm-clean a:active {
      padding: 10px 20px;
      padding-right: 58px;
      font-size: 18px;
      line-height: 17px;
      text-decoration: none;
      padding-right: 0;
      padding-left: 0; }
    .sm-clean a span.sub-arrow {
      position: absolute;
      top: 50%;
      margin-top: -17px;
      left: -35px;
      right: auto;
      width: 34px;
      height: 34px;
      overflow: hidden;
      font: bold 18px/34px monospace !important;
      text-align: center;
      text-shadow: none;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      border-radius: 5px; }
  .sm-clean a.disabled {
    color: #bbbbbb; }
  .sm-clean a.highlighted span.sub-arrow:before {
    display: block;
    content: '-'; }
  .sm-clean > li:first-child {
    border-top: 0; }
    .sm-clean > li:first-child > a {
      -webkit-border-radius: 5px 5px 0 0;
      -moz-border-radius: 5px 5px 0 0;
      -ms-border-radius: 5px 5px 0 0;
      -o-border-radius: 5px 5px 0 0;
      border-radius: 5px 5px 0 0; }
    .sm-clean > li:first-child > *:not(ul) a {
      -webkit-border-radius: 5px 5px 0 0;
      -moz-border-radius: 5px 5px 0 0;
      -ms-border-radius: 5px 5px 0 0;
      -o-border-radius: 5px 5px 0 0;
      border-radius: 5px 5px 0 0; }
  .sm-clean > li:last-child > a {
    -webkit-border-radius: 0 0 5px 5px;
    -moz-border-radius: 0 0 5px 5px;
    -ms-border-radius: 0 0 5px 5px;
    -o-border-radius: 0 0 5px 5px;
    border-radius: 0 0 5px 5px; }
  .sm-clean > li:last-child > *:not(ul) a {
    -webkit-border-radius: 0 0 5px 5px;
    -moz-border-radius: 0 0 5px 5px;
    -ms-border-radius: 0 0 5px 5px;
    -o-border-radius: 0 0 5px 5px;
    border-radius: 0 0 5px 5px; }
  .sm-clean > li:last-child > *:not(ul) a.highlighted {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
  .sm-clean > li:last-child > ul {
    -webkit-border-radius: 0 0 5px 5px;
    -moz-border-radius: 0 0 5px 5px;
    -ms-border-radius: 0 0 5px 5px;
    -o-border-radius: 0 0 5px 5px;
    border-radius: 0 0 5px 5px; }
    .sm-clean > li:last-child > ul > li:last-child > a {
      -webkit-border-radius: 0 0 5px 5px;
      -moz-border-radius: 0 0 5px 5px;
      -ms-border-radius: 0 0 5px 5px;
      -o-border-radius: 0 0 5px 5px;
      border-radius: 0 0 5px 5px; }
    .sm-clean > li:last-child > ul > li:last-child > *:not(ul) a {
      -webkit-border-radius: 0 0 5px 5px;
      -moz-border-radius: 0 0 5px 5px;
      -ms-border-radius: 0 0 5px 5px;
      -o-border-radius: 0 0 5px 5px;
      border-radius: 0 0 5px 5px; }
    .sm-clean > li:last-child > ul > li:last-child > *:not(ul) a.highlighted {
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0; }
    .sm-clean > li:last-child > ul > li:last-child > ul {
      -webkit-border-radius: 0 0 5px 5px;
      -moz-border-radius: 0 0 5px 5px;
      -ms-border-radius: 0 0 5px 5px;
      -o-border-radius: 0 0 5px 5px;
      border-radius: 0 0 5px 5px; }
      .sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > a {
        -webkit-border-radius: 0 0 5px 5px;
        -moz-border-radius: 0 0 5px 5px;
        -ms-border-radius: 0 0 5px 5px;
        -o-border-radius: 0 0 5px 5px;
        border-radius: 0 0 5px 5px; }
      .sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a {
        -webkit-border-radius: 0 0 5px 5px;
        -moz-border-radius: 0 0 5px 5px;
        -ms-border-radius: 0 0 5px 5px;
        -o-border-radius: 0 0 5px 5px;
        border-radius: 0 0 5px 5px; }
      .sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a.highlighted {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0; }
      .sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > ul {
        -webkit-border-radius: 0 0 5px 5px;
        -moz-border-radius: 0 0 5px 5px;
        -ms-border-radius: 0 0 5px 5px;
        -o-border-radius: 0 0 5px 5px;
        border-radius: 0 0 5px 5px; }
        .sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a {
          -webkit-border-radius: 0 0 5px 5px;
          -moz-border-radius: 0 0 5px 5px;
          -ms-border-radius: 0 0 5px 5px;
          -o-border-radius: 0 0 5px 5px;
          border-radius: 0 0 5px 5px; }
        .sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a {
          -webkit-border-radius: 0 0 5px 5px;
          -moz-border-radius: 0 0 5px 5px;
          -ms-border-radius: 0 0 5px 5px;
          -o-border-radius: 0 0 5px 5px;
          border-radius: 0 0 5px 5px; }
        .sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a.highlighted {
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
        .sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul {
          -webkit-border-radius: 0 0 5px 5px;
          -moz-border-radius: 0 0 5px 5px;
          -ms-border-radius: 0 0 5px 5px;
          -o-border-radius: 0 0 5px 5px;
          border-radius: 0 0 5px 5px; }
          .sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a {
            -webkit-border-radius: 0 0 5px 5px;
            -moz-border-radius: 0 0 5px 5px;
            -ms-border-radius: 0 0 5px 5px;
            -o-border-radius: 0 0 5px 5px;
            border-radius: 0 0 5px 5px; }
          .sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a {
            -webkit-border-radius: 0 0 5px 5px;
            -moz-border-radius: 0 0 5px 5px;
            -ms-border-radius: 0 0 5px 5px;
            -o-border-radius: 0 0 5px 5px;
            border-radius: 0 0 5px 5px; }
          .sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a.highlighted {
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
          .sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul {
            -webkit-border-radius: 0 0 5px 5px;
            -moz-border-radius: 0 0 5px 5px;
            -ms-border-radius: 0 0 5px 5px;
            -o-border-radius: 0 0 5px 5px;
            border-radius: 0 0 5px 5px; }
          .sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a.highlighted {
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
        .sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a.highlighted {
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
      .sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > a.highlighted {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0; }
    .sm-clean > li:last-child > ul > li:last-child > a.highlighted {
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0; }
  .sm-clean > li:last-child > a.highlighted {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
  .sm-clean ul ul a {
    border-left: 16px solid transparent; }
    .sm-clean ul ul a:hover {
      border-left: 16px solid transparent; }
    .sm-clean ul ul a:focus {
      border-left: 16px solid transparent; }
    .sm-clean ul ul a:active {
      border-left: 16px solid transparent; }
  .sm-clean ul ul ul a {
    border-left: 24px solid transparent; }
    .sm-clean ul ul ul a:hover {
      border-left: 24px solid transparent; }
    .sm-clean ul ul ul a:focus {
      border-left: 24px solid transparent; }
    .sm-clean ul ul ul a:active {
      border-left: 24px solid transparent; }
  .sm-clean ul ul ul ul a {
    border-left: 32px solid transparent; }
    .sm-clean ul ul ul ul a:hover {
      border-left: 32px solid transparent; }
    .sm-clean ul ul ul ul a:focus {
      border-left: 32px solid transparent; }
    .sm-clean ul ul ul ul a:active {
      border-left: 32px solid transparent; }
  .sm-clean ul ul ul ul ul a {
    border-left: 40px solid transparent; }
    .sm-clean ul ul ul ul ul a:hover {
      border-left: 40px solid transparent; }
    .sm-clean ul ul ul ul ul a:focus {
      border-left: 40px solid transparent; }
    .sm-clean ul ul ul ul ul a:active {
      border-left: 40px solid transparent; }
  .sm-clean li a.current {
    color: #e6b02f; }
  .sm-clean .current_page_item a {
    color: #e6b02f; }

.main-menu {
  float: none;
  max-width: 200px;
  width: 100%;
  position: relative;
  z-index: 9999; }
  .main-menu ul {
    width: 12em; }

*::selection {
  background-color: #ffcc00; }

.body-wrapper {
  margin: 30px;
  background-color: #fff;
  position: relative; }

.doc-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: #fff; }

.animate {
  opacity: 0;
  transition: .3s ease-in;
  -ms-transform: translate(0px, 70px);
  -webkit-transform: translate(0px, 70px);
  transform: translate(0px, 70px); }

.animate.show-it {
  opacity: 1;
  -ms-transform: translate(0px, 0px);
  -webkit-transform: translate(0px, 0px);
  transform: translate(0px, 0px); }

.wait-03s {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s; }

.content-1140 {
  width: 1140px; }
  .content-1140 img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto; }

.content-970 {
  width: 970px; }
  .content-970 img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto; }

.content-945 {
  width: 945px; }
  .content-945 img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto; }

.content-750 {
  width: 750px; }
  .content-750 img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto; }

.header-holder {
  width: 1140px;
  transition: margin-top 0.7s ease; }

.header-holder.down {
  margin-top: 70px; }

.site-logo {
  padding: 0; }

.header-logo {
  padding: 80px 0; }
  .header-logo img {
    width: 130px;
    transition: opacity 0.3s linear; }
    .header-logo img:hover {
      opacity: 0.8; }
  .header-logo .svg-convert path {
    fill: #e6b02f; }

.toggle-holder {
  width: 20px;
  height: 21px;
  top: 104px;
  right: 0;
  z-index: 1; }

#toggle:hover {
  cursor: pointer; }
  #toggle:hover .second-menu-line {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }

#toggle div {
  height: 3px;
  margin-bottom: 6px;
  background-color: #000; }

#toggle .second-menu-line {
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0); }

#toggle .third-menu-line {
  margin-bottom: 0; }

.menu-wraper {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  top: 0;
  left: 0;
  z-index: 99;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.5s, opacity 0.7s ease;
  cursor: url(../images/close.png), auto; }

.menu-wraper.show {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s; }

.menu-wraper.center-relative::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important; }

.menu-holder {
  color: #fff;
  position: relative;
  width: 1270px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
  margin-top: 140px;
  transition: opacity 0.7s ease, margin-top 0.7s ease; }

.menu-holder.show {
  opacity: 1;
  margin-top: 70px;
  transition-delay: 0.3s; }

.menu-left-part {
  float: left;
  cursor: auto;
  width: 50%; }
  .menu-left-part:after {
    content: "";
    position: absolute;
    display: block;
    border-right: 1px solid #1a1a1a;
    height: 110%;
    left: 50%;
    left: calc(50% - 1px);
    top: 0; }

.menu-left-text {
  font-family: "Playfair Display", serif;
  font-weight: 400;
  text-align: right;
  padding-right: 100px;
  font-size: 47px;
  line-height: 60px;
  padding-top: 50px; }
  .menu-left-text a {
    color: #fff;
    border-bottom: 2px solid;
    display: inline-block; }
  .menu-left-text cite {
    font-size: 2rem;
    line-height: 2.5rem;
    font-style: normal;
    display: inline-block;
    margin-top: 1rem; }

.menu-right-part {
  float: right;
  width: 50%; }

#header-main-menu {
  font-weight: 300;
  text-align: left;
  padding-left: 100px;
  margin-top: 60px;
  display: inline-block;
  cursor: auto; }
  #header-main-menu .search-form {
    max-width: 350px; }
  #header-main-menu .search-field {
    font-family: "Roboto", serif;
    max-width: 350px;
    width: 90%;
    color: #fff;
    font-weight: 300;
    font-size: 24px;
    line-height: 24px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #000;
    padding-bottom: 5px;
    text-align: left;
    padding-top: 8px;
    transition: border 0.4s ease;
    -webkit-appearance: none; }
    #header-main-menu .search-field::-ms-clear {
      display: none;
      width: 0;
      height: 0; }
    #header-main-menu .search-field::-ms-reveal {
      display: none;
      width: 0;
      height: 0; }
    #header-main-menu .search-field::-webkit-search-decoration {
      display: none; }
    #header-main-menu .search-field::-webkit-search-cancel-button {
      display: none; }
    #header-main-menu .search-field::-webkit-search-results-button {
      display: none; }
    #header-main-menu .search-field::-webkit-search-results-decoration {
      display: none; }
    #header-main-menu .search-field:focus {
      border-bottom: 2px solid #fff; }
    #header-main-menu .search-field::-webkit-input-placeholder {
      font-family: "Roboto", serif;
      font-weight: 300;
      color: #fff;
      font-size: 24px;
      line-height: 24px;
      opacity: 1;
      text-align: left; }
    #header-main-menu .search-field::-moz-placeholder {
      font-family: "Roboto", serif;
      font-weight: 300;
      color: #fff;
      font-size: 24px;
      line-height: 24px;
      opacity: 1;
      text-align: left; }
    #header-main-menu .search-field:-ms-input-placeholder {
      font-family: "Roboto", serif;
      font-weight: 300;
      color: #fff;
      font-size: 24px;
      line-height: 24px;
      opacity: 1;
      text-align: left; }
    #header-main-menu .search-field:-moz-placeholder {
      font-family: "Roboto", serif;
      font-weight: 300;
      color: #fff;
      font-size: 24px;
      line-height: 24px;
      opacity: 1;
      text-align: left; }

.main-menu.sm-clean {
  background-color: transparent; }
  .main-menu.sm-clean a {
    font-size: 24px;
    line-height: 24px;
    color: #fff; }
    .main-menu.sm-clean a:hover {
      color: #e6b02f; }
  .main-menu.sm-clean .sub-menu a {
    font-size: 18px;
    line-height: 18px;
    border-left: 0; }
  .main-menu.sm-clean .children a {
    font-size: 18px;
    line-height: 18px;
    border-left: 0; }

.widget_search .search-field {
  font-family: "Roboto", serif;
  max-width: 350px;
  width: 90%;
  color: #fff;
  font-weight: 300;
  font-size: 24px;
  line-height: 24px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #000;
  padding-bottom: 5px;
  text-align: left;
  padding-top: 8px;
  transition: border 0.4s ease;
  -webkit-appearance: none;
  text-align: center; }
  .widget_search .search-field::-ms-clear {
    display: none;
    width: 0;
    height: 0; }
  .widget_search .search-field::-ms-reveal {
    display: none;
    width: 0;
    height: 0; }
  .widget_search .search-field::-webkit-search-decoration {
    display: none; }
  .widget_search .search-field::-webkit-search-cancel-button {
    display: none; }
  .widget_search .search-field::-webkit-search-results-button {
    display: none; }
  .widget_search .search-field::-webkit-search-results-decoration {
    display: none; }
  .widget_search .search-field:focus {
    border-bottom: 2px solid #fff; }
  .widget_search .search-field::-webkit-input-placeholder {
    font-family: "Roboto", serif;
    font-weight: 300;
    color: #fff;
    font-size: 24px;
    line-height: 24px;
    opacity: 1;
    text-align: left;
    text-align: center; }
  .widget_search .search-field::-moz-placeholder {
    font-family: "Roboto", serif;
    font-weight: 300;
    color: #fff;
    font-size: 24px;
    line-height: 24px;
    opacity: 1;
    text-align: left;
    text-align: center; }
  .widget_search .search-field:-ms-input-placeholder {
    font-family: "Roboto", serif;
    font-weight: 300;
    color: #fff;
    font-size: 24px;
    line-height: 24px;
    opacity: 1;
    text-align: left;
    text-align: center; }
  .widget_search .search-field:-moz-placeholder {
    font-family: "Roboto", serif;
    font-weight: 300;
    color: #fff;
    font-size: 24px;
    line-height: 24px;
    opacity: 1;
    text-align: left;
    text-align: center; }

.menu-portfolio-category {
  padding-left: 100px;
  padding-top: 50px;
  cursor: auto;
  max-width: 300px; }

.portfolio-category a {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 100%;
  font-size: 10px;
  color: #fff;
  letter-spacing: 2px;
  padding-left: 2px;
  text-transform: uppercase;
  margin-right: 20px;
  display: inline-block; }
  .portfolio-category a:last-child {
    margin-right: 0; }

.blog-item-holder {
  margin: 100px 0;
  text-align: center; }
  .blog-item-holder:first-of-type {
    margin-top: 0; }
  .blog-item-holder .post-thumbnail {
    width: 555px;
    max-width: 45%;
    overflow: hidden;
    line-height: 0;
    transition: all 0.3s ease; }
    .blog-item-holder .post-thumbnail a {
      transition: all 0.3s ease; }
  .blog-item-holder h2.entry-title {
    display: block;
    font-family: "Playfair Display", serif;
    font-weight: 400;
    font-size: 44px;
    line-height: 44px; }
    .blog-item-holder h2.entry-title:hover + .read-more-arrow img {
      transform: translate(10px); }
    .blog-item-holder h2.entry-title cite {
      display: block;
      width: 90%;
      margin-top: 1rem;
      font-size: 2rem;
      line-height: 2.5rem;
      font-style: normal; }

.blog-item-holder.has-post-thumbnail:nth-of-type(2n+1) .post-thumbnail {
  float: left;
  text-align: right;
  margin-right: 80px; }

.blog-item-holder.has-post-thumbnail:nth-of-type(2n+1) .entry-holder {
  float: left; }

.blog-item-holder.has-post-thumbnail:nth-of-type(2n) .post-thumbnail {
  float: right;
  text-align: left;
  margin-left: 80px; }

.blog-item-holder.has-post-thumbnail:nth-of-type(2n) .entry-holder {
  float: right; }

.blog-item-holder.has-post-thumbnail .entry-holder {
  width: 440px;
  max-width: 45%;
  text-align: left; }

.post-num {
  font-family: "Playfair Display", serif;
  font-weight: 400;
  font-size: 100px;
  line-height: 100%;
  margin-top: -30px; }
  .post-num span {
    letter-spacing: -10px; }
  .post-num .separator {
    color: #eaeaea;
    vertical-align: -20px; }
  .post-num .total-posts {
    color: #eaeaea;
    vertical-align: -40px;
    margin-left: -10px; }

.entry-info {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 2px;
  line-height: 22px;
  text-transform: uppercase;
  padding-top: 40px;
  padding-bottom: 50px; }
  .entry-info ul {
    padding: 0;
    margin: 0; }
  .entry-info .cat-links li {
    display: inline-block; }
    .entry-info .cat-links li:after {
      content: ",";
      padding-right: 5px; }
    .entry-info .cat-links li:last-child:after {
      display: none; }

.entry-info-left {
  display: inline-block;
  max-width: 180px;
  padding-right: 40px;
  text-align: left; }

.entry-info-right {
  display: inline-block;
  vertical-align: top;
  text-align: left; }

.read-more-arrow img {
  padding-top: 20px;
  transition: transform .3s ease;
  width: 50px;
  height: 20px; }
  .read-more-arrow img:hover {
    transform: translate(10px); }

.load-more-posts {
  margin-top: 50px;
  transition: margin .3s linear; }

.more-posts-index {
  position: relative;
  display: inline-block;
  cursor: pointer;
  border-bottom: 2px solid;
  padding: 10px 30px;
  color: #000 !important; }
  .more-posts-index:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    border-left: 2px solid;
    border-right: 2px solid;
    -webkit-transition: all ease 0.3s 0.15s;
    transition: all ease 0.3s 0.15s; }
  .more-posts-index:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    border-top: 2px solid;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s; }
  .more-posts-index:hover:before {
    height: 100%;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s; }
  .more-posts-index:hover:after {
    left: 0;
    width: 100%;
    -webkit-transition: all ease 0.3s 0.15s;
    transition: all ease 0.3s 0.15s; }

.page .site-content {
  color: #4b4b4b; }

.page h1.entry-title {
  font-family: "Playfair Display", serif;
  font-size: 47px;
  line-height: 60px;
  font-weight: 400;
  max-width: 650px;
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 35px;
  text-align: center; }
  .page h1.entry-title a {
    position: relative;
    display: inline-block;
    color: #e6b02f; }
    .page h1.entry-title a:after {
      content: '';
      display: block;
      width: 0;
      height: 3px;
      background-color: #e6b02f;
      transition: width .3s;
      position: absolute;
      left: 0;
      bottom: 0; }
    .page h1.entry-title a:hover:after {
      width: 100%; }

.page-desc {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 1px; }

.info-code {
  padding: 10px 0; }

.info-code-title {
  font-size: 15px;
  line-height: 15px;
  font-style: italic;
  color: #b4b4b4;
  font-weight: 400; }

.info-code-content {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 2px; }

.testimonial-slider-holder {
  font-size: 24px;
  line-height: 43px;
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 100px; }
  .testimonial-slider-holder .slick-prev {
    left: -65px; }
  .testimonial-slider-holder .slick-next {
    right: -65px; }

.progress_bar {
  margin-bottom: 35px; }

.progress_bar_field_holder {
  height: 2px;
  position: relative;
  width: 100%;
  vertical-align: middle;
  overflow: hidden;
  background-color: #d5d5d5;
  max-width: 500px; }

.progress_bar_title {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 15px; }

.progress_bar_field_perecent {
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000; }

.single .site-content {
  color: #4b4b4b; }

.single h1.entry-title {
  font-family: "Playfair Display", serif;
  font-weight: 400;
  color: #000;
  font-size: 60px;
  line-height: 60px;
  margin-bottom: 40px;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  max-width: 580px;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }

.single .entry-info {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: #000; }

.single .post-num {
  text-align: center;
  margin-bottom: 100px; }
  .single .post-num .current-post {
    color: #000; }

.single .wp-link-pages {
  color: #e6b02f; }
  .single .wp-link-pages span {
    color: #191919; }

.single .nav-links {
  margin-top: 70px;
  margin-bottom: 70px;
  position: relative; }
  .single .nav-links:before {
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: #000;
    left: 50%;
    margin-left: -2px; }

.single .nav-previous {
  display: inline-block;
  max-width: 290px;
  text-align: right;
  float: left; }

.single .nav-next {
  display: inline-block;
  float: right;
  max-width: 290px;
  text-align: left; }

.single-post .entry-content {
  font-weight: 300; }

.single-post .nav-previous a {
  float: right; }

.single-post .nav-next a {
  float: left; }

.tags-holder {
  font-family: "Montserrat", sans-serif;
  margin: 50px 0;
  display: inline-block; }
  .tags-holder a {
    color: #b1b1b1;
    border: 1px solid;
    text-decoration: none;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
    border-radius: 50px;
    padding: 5px 20px;
    font-size: 13px; }
    .tags-holder a:last-of-type {
      margin-right: 0; }

.nav-links p {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 11px; }

.nav-previous a {
  font-family: "Playfair Display", serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px; }

.nav-next a {
  font-family: "Playfair Display", serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px; }

.archive-title h1 {
  font-family: "Playfair Display", serif;
  font-weight: 400;
  border-bottom: 2px solid;
  display: inline-block; }

.page-pagination-holder {
  margin: 70px 0; }

.page-numbers.current {
  border: 1px solid #e6b02f;
  color: #e6b02f;
  padding: 7px 12px;
  border-radius: 90%; }

.page-numbers {
  margin: 0 5px;
  font-size: 16px; }
  .page-numbers:hover {
    color: #e6b02f; }

.search .search-title h1 {
  font-family: "Playfair Display", serif;
  font-weight: 400;
  border-bottom: 2px solid;
  display: inline-block; }

.search .blog-item-holder {
  padding: 0;
  margin: 0; }
  .search .blog-item-holder .entry-title a {
    font-size: 35px;
    line-height: 40px; }

.header-content {
  padding-bottom: 100px;
  text-align: center; }

.grid-item.quote-item {
  font-family: "Playfair Display", serif;
  width: 400px;
  font-size: 40px;
  line-height: 60px;
  text-align: center; }
  .grid-item.quote-item:before {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    display: block;
    content: "\201C";
    font-size: 470px;
    line-height: 100%;
    color: #e6b02f;
    margin-bottom: -346px;
    margin-top: -50px; }

.grid {
  width: 1300px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  text-align: center;
  list-style: none;
  max-width: 100%;
  padding-left: 0; }

.item-wrapper {
  display: inline-block;
  position: relative; }

.grid-item {
  display: block;
  vertical-align: middle;
  font-size: 0;
  line-height: 0;
  margin: 3%;
  max-width: 43%; }
  .grid-item img {
    display: block;
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%; }
  .grid-item:hover .portfolio-text-holder {
    opacity: 1; }
  .grid-item .portfolio-arrow img {
    margin-left: auto;
    margin-right: auto;
    width: 35px;
    height: 20px;
    margin-top: 30px;
    transition: transform .3s ease; }
    .grid-item .portfolio-arrow img:hover {
      transform: translate(10px); }

.grid-item.hidden {
  display: none !important; }

.grid-item.loaded {
  display: inline-block !important; }

.portfolio-text-holder {
  position: absolute;
  top: 30px;
  left: 30px;
  bottom: 30px;
  right: 30px;
  font-size: 20px;
  background-color: white;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease-out; }
  .portfolio-text-holder .portfolio-text {
    font-family: "Playfair Display", serif;
    font-size: 36px;
    line-height: 100%; }
    .portfolio-text-holder .portfolio-text:hover + p + .portfolio-arrow a img {
      transform: translate(10px); }
  .portfolio-text-holder .portfolio-category {
    padding-top: 20px; }
    .portfolio-text-holder .portfolio-category a {
      font-weight: 700;
      color: #e6b02f;
      margin-right: 0; }
      .portfolio-text-holder .portfolio-category a:after {
        content: "/";
        padding: 0 5px; }
      .portfolio-text-holder .portfolio-category a:last-child:after {
        display: none; }

.more-posts-portfolio, .fazer-marcacao {
  position: relative;
  display: inline-block;
  cursor: pointer;
  border-bottom: 2px solid;
  padding: 10px 30px;
  color: #000 !important; }
  .more-posts-portfolio:before, .fazer-marcacao:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    border-left: 2px solid;
    border-right: 2px solid;
    -webkit-transition: all ease 0.3s 0.15s;
    transition: all ease 0.3s 0.15s; }
  .more-posts-portfolio:after, .fazer-marcacao:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    border-top: 2px solid;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s; }
  .more-posts-portfolio:hover:before, .fazer-marcacao:hover:before {
    height: 100%;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s; }
  .more-posts-portfolio:hover:after, .fazer-marcacao:hover:after {
    left: 0;
    width: 100%;
    -webkit-transition: all ease 0.3s 0.15s;
    transition: all ease 0.3s 0.15s; }

.load-more-portfolio {
  margin-top: 50px; }

.single-portfolio .top-content {
  text-align: center;
  padding-bottom: 40px; }
  .single-portfolio .top-content .slick-dotted.slick-slider {
    margin-bottom: 75px; }

.single-portfolio .portfolio-item-info {
  width: 180px;
  float: left; }

.single-portfolio .content-wrapper {
  width: 580px;
  float: right; }

.single-portfolio .nav-previous {
  float: left; }
  .single-portfolio .nav-previous a img {
    transition: transform .3s ease;
    width: 35px;
    height: 20px; }
    .single-portfolio .nav-previous a img:hover {
      transform: translate(-10px); }

.single-portfolio .nav-next {
  float: left;
  margin-left: 30px; }
  .single-portfolio .nav-next a img {
    transition: transform .3s ease;
    width: 35px;
    height: 20px; }
    .single-portfolio .nav-next a img:hover {
      transform: translate(10px); }

.portfolio-nav {
  padding-top: 30px; }

.thumb-header {
  margin-top: -120px; }

.single-gallery h1.entry-title {
  font-family: "Playfair Display", serif;
  font-weight: 400;
  font-size: 60px;
  line-height: 60px;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  max-width: 580px; }

.single-gallery .entry-content {
  padding-top: 50px; }

.big-text {
  font-family: "Playfair Display", serif;
  font-size: 46px;
  line-height: 50px;
  color: #000;
  padding: 0;
  font-weight: 400; }

.contact-form {
  max-width: 100%;
  width: 770px;
  margin: 0 auto;
  font-size: 22px;
  color: #4b4b4b; }
  .contact-form input[type=text] {
    color: #4b4b4b;
    border: 0;
    border-bottom: 1px solid;
    height: 28px;
    font-size: 18px;
    line-height: 25px;
    font-family: "Roboto", serif;
    font-weight: 300;
    width: 100%;
    padding: 0;
    margin-bottom: 40px;
    padding-bottom: 5px; }
    .contact-form input[type=text]::-webkit-input-placeholder {
      font-family: "Roboto", serif;
      color: #4b4b4b;
      font-weight: 300;
      font-size: 18px;
      line-height: 25px; }
    .contact-form input[type=text]::-moz-placeholder {
      font-family: "Roboto", serif;
      color: #4b4b4b;
      font-weight: 300;
      font-size: 18px;
      line-height: 25px;
      opacity: 1; }
    .contact-form input[type=text]:-ms-input-placeholder {
      font-family: "Roboto", serif;
      color: #4b4b4b;
      font-weight: 300;
      font-size: 18px;
      line-height: 25px; }
    .contact-form input[type=text]:-moz-placeholder {
      font-family: "Roboto", serif;
      color: #4b4b4b;
      font-weight: 300;
      font-size: 18px;
      line-height: 25px;
      opacity: 1; }
  .contact-form input[type=email] {
    color: #4b4b4b;
    border: 0;
    border-bottom: 1px solid;
    height: 28px;
    font-size: 18px;
    line-height: 25px;
    font-family: "Roboto", serif;
    font-weight: 300;
    width: 100%;
    padding: 0;
    margin-bottom: 40px;
    padding-bottom: 5px; }
    .contact-form input[type=email]::-webkit-input-placeholder {
      font-family: "Roboto", serif;
      color: #4b4b4b;
      font-weight: 300;
      font-size: 18px;
      line-height: 25px; }
    .contact-form input[type=email]::-moz-placeholder {
      font-family: "Roboto", serif;
      color: #4b4b4b;
      font-weight: 300;
      font-size: 18px;
      line-height: 25px;
      opacity: 1; }
    .contact-form input[type=email]:-ms-input-placeholder {
      font-family: "Roboto", serif;
      color: #4b4b4b;
      font-weight: 300;
      font-size: 18px;
      line-height: 25px; }
    .contact-form input[type=email]:-moz-placeholder {
      font-family: "Roboto", serif;
      color: #4b4b4b;
      font-weight: 300;
      font-size: 18px;
      line-height: 25px;
      opacity: 1; }
  .contact-form textarea {
    color: #4b4b4b;
    border: 0;
    border-bottom: 1px solid;
    height: 28px;
    font-size: 18px;
    line-height: 25px;
    font-family: "Roboto", serif;
    font-weight: 300;
    width: 100%;
    padding: 0;
    margin-bottom: 40px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    padding-top: 8px;
    overflow: hidden; }
    .contact-form textarea::-webkit-input-placeholder {
      font-family: "Roboto", serif;
      color: #4b4b4b;
      font-weight: 300;
      font-size: 18px;
      line-height: 25px; }
    .contact-form textarea::-moz-placeholder {
      font-family: "Roboto", serif;
      color: #4b4b4b;
      font-weight: 300;
      font-size: 18px;
      line-height: 25px;
      opacity: 1; }
    .contact-form textarea:-ms-input-placeholder {
      font-family: "Roboto", serif;
      color: #4b4b4b;
      font-weight: 300;
      font-size: 18px;
      line-height: 25px; }
    .contact-form textarea:-moz-placeholder {
      font-family: "Roboto", serif;
      color: #4b4b4b;
      font-weight: 300;
      font-size: 18px;
      line-height: 25px;
      opacity: 1; }
    .contact-form textarea:focus {
      height: 150px;
      overflow: auto; }
  .contact-form input[name="your-name"] {
    margin-top: 0;
    padding-top: 0; }
  .contact-form input[type=submit] {
    text-align: center;
    padding: 10px 0px;
    cursor: pointer;
    background: transparent;
    font-family: "Roboto", serif;
    font-weight: 300;
    width: 100%;
    font-size: 18px;
    border: 0;
    border-bottom: 2px solid;
    position: relative;
    z-index: 3; }

.contact-submit-holder {
  position: relative;
  margin-bottom: 25px; }
  .contact-submit-holder:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    border-top: 2px solid;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s; }
  .contact-submit-holder:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    border-left: 2px solid;
    border-right: 2px solid;
    -webkit-transition: all ease 0.3s 0.15s;
    transition: all ease 0.3s 0.15s; }
  .contact-submit-holder:hover:before {
    height: 100%;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s; }
  .contact-submit-holder:hover:after {
    left: 0;
    width: 100%;
    -webkit-transition: all ease 0.3s 0.15s;
    transition: all ease 0.3s 0.15s; }

.footer {
  font-size: 16px;
  line-height: 36px;
  margin-top: 70px;
  padding-bottom: 110px;
  clear: both;
  color: #fff;
  text-align: center;
  background: #000; }
  .footer a {
    color: #fff; }

.footer-logo {
  padding-bottom: 130px;
  position: relative;
  width: 130px;
  height: 130px;
  margin: 0 auto;
  padding-top: 90px; }
  .footer-logo:after {
    content: "";
    display: block;
    height: 77px;
    width: 2px;
    background: #1a1a1a;
    position: absolute;
    left: 50%;
    margin-left: -2px;
    margin-top: 10px; }
  .footer-logo .svg-convert path {
    fill: #fff; }

.footer-text {
  padding-bottom: 50px; }

.footer-first-line {
  font-family: "Playfair Display", serif;
  font-size: 47px;
  line-height: 60px; }
  .footer-first-line a {
    border-bottom: 2px solid; }

.footer-second-line {
  font-weight: 400;
  padding-top: 20px; }
  .footer-second-line a {
    border-bottom: 1px solid; }

.our-position-holder {
  font-family: "Playfair Display", serif;
  position: relative;
  font-size: 24px; }
  .our-position-holder:after {
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    position: absolute;
    background: #1a1a1a;
    bottom: 0; }
  .our-position-holder.about:after {
    height: 1px;
    background: #d5d5d5;
    margin-bottom: 10px;
    margin-top: 10px;
    position: static; }

.our-position-left {
  display: inline-block;
  padding-right: 50px; }

.our-position-right {
  display: inline-block; }

ul#footer-sidebar {
  padding: 30px 0;
  font-size: 14px;
  line-height: 28px;
  border-bottom: 2px solid #1a1a1a; }
  ul#footer-sidebar li.widget {
    width: 33.3%;
    padding-right: 4%;
    float: left;
    box-sizing: border-box; }
    ul#footer-sidebar li.widget:nth-child(2n) {
      padding-right: 0;
      margin-bottom: 50px; }
  ul#footer-sidebar:after {
    clear: both;
    content: "";
    display: block; }

h4.widgettitle {
  font-size: 16px;
  font-family: "Playfair Display", serif; }

.tagcloud a {
  font-size: 14px !important;
  display: block; }
  .tagcloud a.current {
    color: #e6b02f; }

.widget_recent_entries a {
  border-bottom: 1px solid; }

.widget_recent_entries span.post-date {
  display: block;
  font-size: 12px;
  line-height: 100%;
  margin-bottom: 20px; }

ul.copyright-holder {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 10px; }
  ul.copyright-holder:after {
    content: "";
    display: block;
    clear: both; }

li.copyright-footer {
  float: left;
  text-align: left;
  width: 50%; }

li.social-footer {
  float: right;
  text-align: right;
  width: 50%; }
  li.social-footer a {
    padding-left: 20px; }
    li.social-footer a:first-child {
      padding-left: 0; }

@media screen and (max-width: 1600px) {
  .content-1140 {
    max-width: 95%; }
  .menu-right-part {
    width: 45%; }
  .menu-left-part {
    margin-left: 5%; }
    .menu-left-part:after {
      left: 55%;
      left: calc(55% - 1px); } }

@media screen and (max-width: 1450px) {
  .one_half {
    margin-right: 8%;
    float: left;
    position: relative;
    margin-bottom: 37px;
    width: 46%; }
  .one_third {
    margin-right: 8%;
    float: left;
    position: relative;
    margin-bottom: 37px;
    width: 28%; }
  .two_third {
    margin-right: 8%;
    float: left;
    position: relative;
    margin-bottom: 37px;
    width: 64%; }
  .three_fourth {
    margin-right: 8%;
    float: left;
    position: relative;
    margin-bottom: 37px;
    width: 73%; }
  .one_fourth {
    margin-right: 8%;
    float: left;
    position: relative;
    margin-bottom: 37px;
    width: 19%; }
  blockquote.inline-blockquote {
    display: block;
    margin: 50px 0;
    width: auto; } }

@media screen and (max-width: 1220px) {
  blockquote {
    font-size: 17px;
    line-height: 28px;
    text-align: center; }
    blockquote:before {
      line-height: 150px;
      font-size: 150px;
      position: relative;
      top: 0;
      left: 0;
      text-align: center;
      display: block;
      margin-bottom: -60px; }
  .menu-left-text {
    font-size: 35px;
    line-height: 50px; } }

@media screen and (max-width: 1020px) {
  .header-logo {
    padding: 50px 0; }
  .toggle-holder {
    top: 71px;
    right: 40px; }
  .page h1.entry-title {
    font-size: 40px;
    line-height: 45px;
    max-width: 95%; }
  .single h1.entry-title {
    font-size: 40px;
    line-height: 44px;
    margin-bottom: 0;
    max-width: 95%; }
  .single .post-num {
    margin-bottom: 50px; }
  .content-970 {
    width: 95%; }
  .content-945 {
    width: 95%; }
  .content-750 {
    width: 95%; }
  .menu-left-part {
    display: none; }
  #header-main-menu {
    text-align: center;
    padding-left: 0;
    margin-top: 30px; }
    #header-main-menu .search-field {
      text-align: center; }
      #header-main-menu .search-field::-webkit-input-placeholder {
        text-align: center; }
      #header-main-menu .search-field::-moz-placeholder {
        text-align: center; }
      #header-main-menu .search-field:-ms-input-placeholder {
        text-align: center; }
      #header-main-menu .search-field:-moz-placeholder {
        text-align: center; }
  .menu-right-part {
    float: none;
    width: 100%;
    margin: 0 auto;
    text-align: center; }
  .main-menu {
    max-width: 100%; }
  .menu-portfolio-category {
    padding-left: 0;
    margin: 0 auto; }
  .one_half {
    width: 100%;
    float: none;
    margin-right: 0;
    margin-left: 0;
    text-align: center; }
  .one_third {
    width: 100%;
    float: none;
    margin-right: 0;
    margin-left: 0;
    text-align: center; }
  .one_fourth {
    width: 100%;
    float: none;
    margin-right: 0;
    margin-left: 0;
    text-align: center; }
  .two_third {
    width: 100%;
    float: none;
    margin-right: 0;
    margin-left: 0;
    text-align: center; }
  .three_fourth {
    width: 100%;
    float: none;
    margin-right: 0;
    margin-left: 0;
    text-align: center; }
  .progress_bar_field_holder {
    width: 100%;
    max-width: 100%; }
  .blog-item-holder {
    padding: 30px 0;
    margin: 30px 0; }
    .blog-item-holder .post-thumbnail {
      float: none !important;
      text-align: center !important;
      margin-left: auto !important;
      margin-right: auto !important;
      margin-bottom: 30px;
      max-width: 100%;
      width: 100%; }
    .blog-item-holder h2.entry-title {
      font-size: 40px; }
  .blog-item-holder.has-post-thumbnail .entry-holder {
    float: none !important;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    width: 100%; }
  .post-num {
    font-size: 65px;
    margin-top: -15px; }
  .entry-info {
    padding-bottom: 20px; }
  .slick-dotted.slick-slider {
    margin-bottom: 90px; }
  .info-code {
    padding: 10px 20px;
    display: inline-block;
    text-align: center; }
  .grid-item.quote-item {
    font-size: 30px;
    line-height: 45px; }
    .grid-item.quote-item:before {
      font-size: 260px;
      margin-bottom: -210px; }
  .portfolio-text-holder {
    top: 10px;
    left: 10px;
    bottom: 10px;
    right: 10px; }
  .carousel-slider .slick-slide .item-text {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 1 !important; }
    .carousel-slider .slick-slide .item-text a {
      font-size: 25px;
      line-height: 35px;
      padding-top: 20px;
      margin-top: 0 !important; }
  .single-portfolio .entry-content {
    position: relative;
    padding-bottom: 50px; }
  .single-portfolio .portfolio-item-info {
    width: auto;
    float: none;
    text-align: center;
    padding-bottom: 30px; }
  .single-portfolio .content-wrapper {
    width: auto;
    float: none; }
  .portfolio-nav {
    position: absolute;
    bottom: -35px;
    text-align: center;
    display: block;
    width: 100%;
    left: 0; }
    .portfolio-nav .nav-previous {
      float: none; }
    .portfolio-nav .nav-next {
      float: none; }
  .footer {
    margin-top: 40px;
    padding-bottom: 30px; }
  .footer-first-line {
    font-size: 35px;
    line-height: 35px; }
  .our-position-left {
    display: block;
    padding-right: 0; }
  .our-position-right {
    display: block; }
  .our-position-holder:before {
    display: none; }
  .our-position-holder:after {
    width: 50%;
    left: 25%; }
  .our-position-holder.about:after {
    width: 100%; }
  ul#footer-sidebar li.widget {
    width: 100%;
    margin-right: 0;
    float: none;
    padding-bottom: 30px; }
    ul#footer-sidebar li.widget:nth-child(3n) {
      margin-bottom: 0; }
  li.copyright-footer {
    float: none;
    text-align: center;
    width: 100%; }
  li.social-footer {
    float: none;
    text-align: center;
    width: 100%; } }

@media screen and (max-width: 805px) {
  p.custom-text-class {
    display: block !important; }
  p.custom-field-class {
    display: block !important;
    width: 100% !important; } }

@media screen and (max-width: 750px) {
  .grid-item {
    display: block !important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 33px;
    max-width: 95%; }
  .grid-item.quote-item {
    margin-top: 60px; }
  .single .nav-links:before {
    display: none; }
  .single .nav-previous {
    display: block;
    max-width: 95%;
    text-align: center;
    float: none;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 40px; }
    .single .nav-previous a {
      float: none;
      font-size: 24px;
      line-height: 30px; }
  .single .nav-next {
    display: block;
    max-width: 95%;
    text-align: center;
    float: none;
    margin-left: auto;
    margin-right: auto; }
    .single .nav-next a {
      float: none;
      font-size: 24px;
      line-height: 30px; }
  .single-portfolio .nav-previous {
    display: inline-block;
    padding-bottom: 0;
    max-width: none;
    margin: 0 15px; }
  .single-portfolio .nav-next {
    display: inline-block;
    padding-bottom: 0;
    max-width: none;
    margin: 0 15px; }
  .carousel-slider .slick-slide img {
    max-height: 300px; } }

.about p {
  font-size: .95rem; }

.load-more-portfolio, .footer-second-line, .portfolio-arrow {
  display: none; }

.item-wrapper a {
  display: block; }

.blog-item-holder.has-post-thumbnail .post-thumbnail {
  text-align: center !important; }

#content .entry-title {
  margin-bottom: 0; }

#content .entry-info {
  padding-top: 0;
  text-align: center; }

#content .content-750.center-relative {
  padding-bottom: 40px; }

.contact-form .error-field {
  color: #ab2121;
  font-size: .9rem;
  display: none; }

.contact-form input.is-invalid-input, .contact-form textarea.is-invalid-input {
  color: #ab2121 !important; }

.contact-form .success-field {
  font-size: .9rem;
  display: none; }

._hj-f5b2a1eb-9b07_widget._hj-f5b2a1eb-9b07_skin_light, ._hj-f5b2a1eb-9b07_widget._hj-f5b2a1eb-9b07_skin_light ._hj-f5b2a1eb-9b07_widget_open_close {
  border: 1px solid #ddd;
  border-radius: 0;
  box-shadow: 0 0 0 0 !important;
  font-family: "Montserrat", sans-serif !important; }

._hj-f5b2a1eb-9b07_widget, ._hj-f5b2a1eb-9b07_widget * {
  font-family: "Montserrat", sans-serif !important; }

li.social-footer {
  line-height: 2rem; }
