$color_1: #000;
$color_2: transparent;
$color_3: black;
$color_4: #ffcc00;
$color_5: #f277ae;
$color_6: white;
$color_7: #21759b;
$color_8: #bbbbbb;
$main_color: #e6b02f;
$color_10: #fff;
$color_11: #eaeaea;
$color_12: #4b4b4b;
$color_13: #b4b4b4;
$color_14: #b1b1b1;
$color_15: #191919;
$font_family_1: "Montserrat", sans-serif;
$font_family_2: 'Roboto', serif;
$font_family_3: 'Playfair Display', serif;
$background_color_1: #ff9;
$background_color_2: transparent;
$background_color_3: red;
$background_color_4: #f277ae;
$background_color_5: #f1f1f1;
$background_color_6: #fff;
$background_color_7: #ffcc00;
$background_color_8: #000;
$background_color_9: #d5d5d5;
$background_main_color: #e6b02f;
$background_color_11: white;

/* Slider */
/* Dots */
/* COLUMNS */
/* MENU */
/* Mobile first layout SmartMenus Core CSS (it's not recommended editing these rules)
   You need this once per page no matter how many menu trees or different themes you use.
-------------------------------------------------------------------------------------------*/
/* WP DEFAULT CLASSES */
/* Text meant only for screen readers. */
/*------------------------------------------------------------------
ver 1.1

[Table of contents]
1. Global CSS
        1.1 Global Header Logo/Menu CSS 
2. Blog Page CSS
3. General Page CSS
4. About Page CSS
5. Single Post CSS
6. Archive Page CSS
7. Search Page CSS
8. Portfolio (Home) Page CSS
        8.1 Portfolio Single Post CSS
9. Gallery Page CSS
        9.1 Gallery Single Post CSS
10. Contact Page CSS
11. Footer CSS
12. Responsive CSS
-------------------------------------------------------------------*/
/* ===================================
    1. Global CSS
====================================== */
/* ===================================
    1.1 Global Header Logo/Menu CSS
====================================== */
/* ===================================
    2. Blog Page CSS
====================================== */
/* ===================================
    3. General Page CSS
====================================== */
/* ===================================
    4. About Page CSS
====================================== */
/* ===================================
    5. Single Post CSS
====================================== */
/* ===================================
    6. Archive Page CSS
====================================== */
/* ===================================
    7. Search Page CSS
====================================== */
/* ===================================
    8. Portfolio (Home) Page CSS
====================================== */
/* ===================================
    8.1 Portfolio Single Post CSS
====================================== */
/* ===================================
    9. Gallery Page CSS
====================================== */
/* ===================================
    9.1 Gallery Single Post CSS
====================================== */
/* ===================================
    10. Contact Page CSS
====================================== */
/* ===================================
    11. Footer CSS
====================================== */
/* ===================================
    12. Responsive CSS
====================================== */
html {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
body {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	line-height: 1;
	font-family: $font_family_2;
	font-size: 17px;
	line-height: 35px;
	font-weight: 400;
	color: $color_1;
	background-color: $background_color_6;
	a {
		text-decoration: none;
		color: $color_1;
		transition: color .2s linear;
		&:hover {
			color: $main_color;
		}
	}
}
div {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
span {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
object {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
iframe {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	width: 100%;
}
h1 {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	padding: 20px 0;
	font-size: 38px;
	line-height: 38px;
}
h2 {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	padding: 20px 0;
	font-size: 34px;
	line-height: 34px;
}
h3 {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	padding: 20px 0;
	font-size: 32px;
	line-height: 32px;
}
h4 {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	padding: 20px 0;
	font-size: 30px;
}
h5 {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	padding: 20px 0;
	font-size: 28px;
}
h6 {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	padding: 20px 0;
	font-size: 26px;
}
p {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
blockquote {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	quotes: none;
	margin-top: 90px;
	margin-bottom: 50px;
	font-size: 24px;
	line-height: 44px;
	color: $color_1;
	font-weight: 400;
	position: relative;
	z-index: 2;
	&:before {
		content: '';
		content: none;
		content: "\201C";
		font-family: $font_family_1;
		color: $color_4;
		font-size: 250px;
		line-height: 0;
		position: absolute;
		left: -40px;
		z-index: 1;
		font-weight: 700;
		top: 70px;
	}
	&:after {
		content: '';
		content: none;
	}
	p {
		position: relative;
		z-index: 2;
	}
}
pre {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	word-wrap: break-word;
	white-space: pre-wrap;
}
abbr {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
address {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	font-size: 32px;
	line-height: 50px;
	font-style: normal;
}
cite {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
code {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
del {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	text-decoration: line-through;
}
dfn {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
em {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	font-style: italic;
}
img {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
ins {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	background-color: $background_color_1;
	color: $color_1;
	text-decoration: none;
}
kbd {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
q {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	quotes: none;
	&:before {
		content: '';
		content: none;
	}
	&:after {
		content: '';
		content: none;
	}
}
samp {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
small {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
strong {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
sub {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	vertical-align: sub;
	font-size: smaller;
}
sup {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	vertical-align: super;
	font-size: smaller;
}
var {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
b {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
i {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
fieldset {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
form {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
label {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
legend {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
caption {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
tfoot {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
thead {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
article {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	display: block;
}
aside {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	display: block;
}
canvas {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
details {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	display: block;
}
figcaption {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	display: block;
}
figure {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	display: block;
}
footer {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	display: block;
	ul {
		list-style: none;
		padding-left: 0;
		margin-left: 0;
	}
}
header {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	display: block;
}
hgroup {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	display: block;
}
menu {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	display: block;
}
nav {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	display: block;
}
section {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	display: block;
}
summary {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
time {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
mark {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	background-color: $background_color_1;
	color: $color_1;
	font-style: italic;
	font-weight: bold;
}
audio {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
a {
	margin: 0;
	padding: 0;
	font-size: 100%;
	outline: none;
	vertical-align: baseline;
	background: transparent;
	img.alignright {
		float: right;
		margin: 5px 0 20px 20px;
	}
	img.alignnone {
		margin: 20px 0px;
	}
	img.alignleft {
		float: left;
		margin: 5px 20px 20px 0;
	}
	img.aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
}
abbr[title] {
	border-bottom: 1px dotted;
	cursor: help;
}
dfn[title] {
	border-bottom: 1px dotted;
	cursor: help;
}
hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #cccccc;
	margin: 1em 0;
	padding: 0;
}
input {
	vertical-align: middle;
	&:focus {
		outline: none;
	}
}
select {
	vertical-align: middle;
}
textarea {
	&:focus {
		outline: none;
	}
}
.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
	.slick-track {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	.slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
.slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0;
	&:focus {
		outline: none;
	}
}
.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}
.slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	&:before {
		display: table;
		content: '';
	}
	&:after {
		display: table;
		content: '';
		clear: both;
	}
}
.slick-loading {
	.slick-track {
		visibility: hidden;
	}
	.slick-slide {
		visibility: hidden;
	}
}
.gallery-item.slick-slide {
	float: none;
	height: 100%;
	min-height: 1px;
	vertical-align: middle;
	position: relative;
	display: inline-block !important;
}
.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
	img {
		display: block;
		margin: 0 auto;
	}
}
.carousel-slider {
	.slick-slide {
		margin: 0 64px;
		opacity: 0.7;
		transition: opacity 0.4s ease;
		img {
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 100%;
		}
		.item-text {
			position: absolute;
			top: 30px;
			left: 30px;
			bottom: 30px;
			right: 30px;
			font-size: 20px;
			background-color: $background_color_11;
			text-align: center;
			opacity: 0;
			transition: opacity 0.3s ease-out;
			a {
				font-family: $font_family_3;
				font-weight: 400;
				font-size: 40px;
				line-height: 45px;
				-ms-word-wrap: break-word;
				word-wrap: break-word;
				transition: opacity 0.4s ease, color .2s linear;
				display: block;
				pointer-events: none;
				cursor: default;
			}
		}
	}
	.slick-slide.slick-center {
		opacity: 1;
		&:hover {
			.item-text {
				opacity: 1;
			}
		}
		.item-text {
			a {
				pointer-events: auto;
				cursor: auto;
			}
		}
	}
}
[dir='rtl'] {
	.slick-slide {
		float: right;
	}
	.slick-prev {
		right: -25px;
		left: auto;
		&:before {
			content: '+';
		}
	}
	.slick-next {
		right: auto;
		left: -25px;
		&:before {
			content: '-';
		}
	}
}
.slick-slide.slick-loading {
	img {
		display: none;
	}
}
.slick-slide.dragging {
	img {
		pointer-events: none;
	}
}
.slick-initialized {
	.slick-slide {
		display: block;
	}
}
.slick-vertical {
	.slick-slide {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}
.slick-arrow.slick-hidden {
	display: none;
}
.slick-prev {
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 50%;
	display: block;
	margin-top: -10px;
	padding: 0;
	cursor: pointer;
	color: $color_2;
	border: none;
	outline: none;
	background: transparent;
	z-index: 10;
	width: 35px;
	height: 20px;
	opacity: 0.3;
	transition: opacity 0.4s ease;
	background-image: url(../images/opta_arrow@2x_left.png);
	background-repeat: no-repeat;
	background-size: 35px 20px;
	background-color: $background_color_2;
	padding: 35px;
	background-position: center;
	&:hover {
		opacity: 1;
		&:before {
			opacity: 1;
		}
	}
	&:focus {
		&:before {
			opacity: 1;
		}
	}
	&:before {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		content: '-';
	}
}
.slick-next {
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 50%;
	display: block;
	margin-top: -10px;
	padding: 0;
	cursor: pointer;
	color: $color_2;
	border: none;
	outline: none;
	background: transparent;
	z-index: 10;
	width: 35px;
	height: 20px;
	opacity: 0.3;
	transition: opacity 0.4s ease;
	right: 0;
	background-image: url("../images/opta_arrow@2x.png");
	background-repeat: no-repeat;
	background-size: 35px 20px;
	background-color: $background_color_2;
	padding: 35px;
	background-position: center;
	&:hover {
		opacity: 1;
		&:before {
			opacity: 1;
		}
	}
	&:focus {
		&:before {
			opacity: 1;
		}
	}
	&:before {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		content: '+';
	}
}
.slick-prev.slick-disabled {
	&:before {
		opacity: .25;
	}
}
.slick-next.slick-disabled {
	&:before {
		opacity: .25;
	}
}
.slick-dotted.slick-slider {
	margin-bottom: 80px;
}
.slick-dots {
	position: absolute;
	bottom: -50px;
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
	li {
		position: relative;
		display: inline-block;
		width: 20px;
		height: 20px;
		margin: 0 5px;
		padding: 0;
		cursor: pointer;
		button {
			font-size: 0;
			line-height: 0;
			display: block;
			width: 20px;
			height: 20px;
			padding: 5px;
			cursor: pointer;
			color: $color_2;
			border: 0;
			outline: none;
			background: transparent;
			&:hover {
				outline: none;
				&:before {
					opacity: 1;
				}
			}
			&:focus {
				outline: none;
				&:before {
					opacity: 1;
				}
			}
			&:before {
				font-size: 6px;
				line-height: 20px;
				position: absolute;
				top: 0;
				left: 0;
				width: 20px;
				height: 20px;
				content: "";
				border-radius: 90%;
				background-color: $background_color_3;
				text-align: center;
				opacity: .25;
				color: $color_3;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				background-color: $background_color_9;
				opacity: 1;
				transition: background-color .3s ease, opacity .3s ease;
			}
		}
		&:hover {
			button {
				&:before {
					background-color: $background_main_color;
					opacity: 0.3;
				}
			}
		}
	}
	li.slick-active {
		button {
			&:before {
				opacity: .75;
				color: $color_3;
				background-color: $background_main_color;
			}
		}
	}
}
*:focus {
	outline: none;
}
.block {
	display: block;
}
.hidden {
	display: none;
}
.clear {
	clear: both;
}
.left {
	float: left;
}
.right {
	float: right;
}
.line-height {
	line-height: 0;
}
.clear-right {
	clear: right;
}
.clear-left {
	clear: left;
}
.center-relative {
	margin-left: auto;
	margin-right: auto;
}
.center-absolute {
	left: 50%;
}
.center-text {
	text-align: center !important;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}
.absolute {
	position: absolute;
}
.fixed {
	position: fixed;
}
.relative {
	position: relative;
}
.margin-0 {
	margin: 0 !important;
	line-height: 0;
	img {
		margin: 0 !important;
		line-height: 0;
	}
}
.top-0 {
	margin-top: 0 !important;
}
.top-5 {
	margin-top: 5px !important;
}
.top-10 {
	margin-top: 10px;
}
.top-15 {
	margin-top: 15px;
}
.top-20 {
	margin-top: 20px;
}
.top-25 {
	margin-top: 25px;
}
.top-30 {
	margin-top: 30px;
}
.top-50 {
	margin-top: 50px;
}
.top-60 {
	margin-top: 60px;
}
.top-70 {
	margin-top: 70px;
}
.top-75 {
	margin-top: 75px;
}
.top-80 {
	margin-top: 80px;
}
.top-100 {
	margin-top: 100px;
}
.top-105 {
	margin-top: 105px;
}
.top-120 {
	margin-top: 120px;
}
.top-150 {
	margin-top: 150px;
}
.top-200 {
	margin-top: 200px;
}
.top-negative-30 {
	margin-top: -30px;
}
.left-35 {
	margin-left: 35px;
}
.right-35 {
	margin-right: 35px;
}
.bottom-0 {
	margin-bottom: 0 !important;
}
.bottom-10 {
	margin-bottom: 10px !important;
}
.bottom-20 {
	margin-bottom: 20px !important;
}
.bottom-25 {
	margin-bottom: 25px !important;
}
.bottom-30 {
	margin-bottom: 30px !important;
}
.bottom-50 {
	margin-bottom: 50px !important;
}
.bottom-100 {
	margin-bottom: 100px !important;
}
.bottom-150 {
	margin-bottom: 150px !important;
}
.bottom-200 {
	margin-bottom: 200px !important;
}
.width-25 {
	width: 25%;
}
.width-50 {
	width: 50%;
}
.width-75 {
	width: 75%;
}
.width-85 {
	width: 85%;
}
.width-100 {
	width: 100%;
}
.sticky {
	font-size: 100%;
}
blockquote.inline-blockquote {
	width: 480px;
	float: left;
	margin-left: -80px;
	margin-right: 100px;
	margin-top: 70px;
}
.wrap-blockquote {
	display: inline;
}
a.button {
	display: inline-block;
	color: $color_5;
	border: 1px solid #f277ae;
	text-align: center;
	padding-bottom: 15px;
	padding-top: 12px;
	padding-left: 50px;
	padding-right: 50px;
	cursor: pointer;
	margin-bottom: 25px;
	vertical-align: middle;
	text-decoration: none;
	border-radius: 50px;
	font-size: 22px;
	transition: all .2s linear;
	&:active {
		position: relative;
		top: 1px;
	}
	&:hover {
		color: $color_6;
		background-color: $background_color_4;
	}
}
ol {
	list-style-type: decimal;
}
table {
	border: 1px solid black;
	padding: 10px;
	vertical-align: middle;
}
th {
	border: 1px solid black;
	padding: 10px;
	vertical-align: middle;
}
td {
	border: 1px solid black;
	padding: 10px;
	vertical-align: middle;
}
.single-content {
	ol {
		display: block;
		list-style-type: decimal;
		margin: 0 0 14px 17px;
	}
	ul {
		display: block;
		list-style-type: disc;
		margin: 0 0 14px 17px;
	}
}
.one {
	margin-right: 14%;
	float: left;
	position: relative;
	margin-bottom: 30px;
	float: none;
	display: block;
	clear: both;
	margin-right: 0;
}
.one_half {
	margin-right: 14%;
	float: left;
	position: relative;
	margin-bottom: 30px;
	width: 43%;
}
.one_third {
	margin-right: 14%;
	float: left;
	position: relative;
	margin-bottom: 30px;
	width: 24%;
}
.two_third {
	margin-right: 14%;
	float: left;
	position: relative;
	margin-bottom: 30px;
	width: 62%;
}
.three_fourth {
	margin-right: 14%;
	float: left;
	position: relative;
	margin-bottom: 30px;
	width: 71.5%;
}
.one_fourth {
	margin-right: 14%;
	float: left;
	position: relative;
	margin-bottom: 30px;
	width: 14.5%;
}
.last {
	margin-right: 0 !important;
	clear: right;
}
.sm {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
	line-height: normal;
	direction: ltr;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	ul {
		display: block;
		list-style: none;
		margin: 0;
		padding: 0;
		line-height: normal;
		direction: ltr;
		-webkit-tap-highlight-color: rgba(0,0,0,0);
		display: none;
	}
	li {
		display: block;
		list-style: none;
		margin: 0;
		padding: 0;
		line-height: normal;
		direction: ltr;
		-webkit-tap-highlight-color: rgba(0,0,0,0);
		position: relative;
	}
	>li {
		>h1 {
			margin: 0;
			padding: 0;
		}
		>h2 {
			margin: 0;
			padding: 0;
		}
		>h3 {
			margin: 0;
			padding: 0;
		}
		>h4 {
			margin: 0;
			padding: 0;
		}
		>h5 {
			margin: 0;
			padding: 0;
		}
		>h6 {
			margin: 0;
			padding: 0;
		}
	}
	a {
		position: relative;
		display: block;
	}
	a.disabled {
		cursor: not-allowed;
	}
	&:after {
		content: "\00a0";
		display: block;
		height: 0;
		font: 0px/0 serif;
		clear: both;
		visibility: hidden;
		overflow: hidden;
	}
	* {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		&:before {
			-moz-box-sizing: border-box;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
		}
		&:after {
			-moz-box-sizing: border-box;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
		}
	}
}
.sm-rtl {
	direction: rtl;
	text-align: right;
	ul {
		direction: rtl;
		text-align: right;
	}
	li {
		direction: rtl;
		text-align: right;
	}
}
.alignnone {
	margin: 5px 20px 20px 0;
}
.aligncenter {
	display: block;
	margin: 5px auto 5px auto;
	display: block;
	margin: 5px auto 5px auto;
}
div.aligncenter {
	display: block;
	margin: 5px auto 5px auto;
}
.alignright {
	float: right;
	margin: 5px 0 20px 20px;
}
.alignleft {
	float: left;
	margin: 5px 20px 20px 0;
}
.wp-caption {
	max-width: 100%;
	img {
		width: auto;
		height: auto;
		max-width: 100%;
		max-height: 100%;
	}
	.wp-caption-text {
		font-size: 14px;
		line-height: 17px;
		margin: 0;
		padding: 0 4px 5px;
		font-style: italic;
		text-align: center;
		a {
			font-weight: 700;
		}
	}
}
.wp-caption.alignnone {
	margin: 20px 0px;
}
.wp-caption.alignleft {
	margin: 5px 20px 20px 0;
	text-align: left;
}
.wp-caption.alignright {
	margin: 5px 0 20px 20px;
	text-align: right;
}
.gallery-caption {
	font-size: 100%;
}
.bypostauthor {
	font-size: 100%;
}
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
	&:focus {
		background-color: $background_color_5;
		border-radius: 3px;
		box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
		clip: auto !important;
		color: $color_7;
		display: block;
		font-size: 14px;
		font-size: 0.875rem;
		font-weight: bold;
		height: auto;
		left: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000;
	}
}
.sm-clean {
	background: #eeeeee;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	border-radius: 5px;
	a {
		padding: 10px 20px;
		padding-right: 58px;
		font-size: 18px;
		line-height: 17px;
		text-decoration: none;
		padding-right: 0;
		padding-left: 0;
		&:hover {
			padding: 10px 20px;
			padding-right: 58px;
			font-size: 18px;
			line-height: 17px;
			text-decoration: none;
			padding-right: 0;
			padding-left: 0;
		}
		&:focus {
			padding: 10px 20px;
			padding-right: 58px;
			font-size: 18px;
			line-height: 17px;
			text-decoration: none;
			padding-right: 0;
			padding-left: 0;
		}
		&:active {
			padding: 10px 20px;
			padding-right: 58px;
			font-size: 18px;
			line-height: 17px;
			text-decoration: none;
			padding-right: 0;
			padding-left: 0;
		}
		span.sub-arrow {
			position: absolute;
			top: 50%;
			margin-top: -17px;
			left: -35px;
			right: auto;
			width: 34px;
			height: 34px;
			overflow: hidden;
			font: bold 18px/34px monospace !important;
			text-align: center;
			text-shadow: none;
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			-ms-border-radius: 5px;
			-o-border-radius: 5px;
			border-radius: 5px;
		}
	}
	a.disabled {
		color: $color_8;
	}
	a.highlighted {
		span.sub-arrow {
			&:before {
				display: block;
				content: '-';
			}
		}
	}
	>li {
		&:first-child {
			>a {
				-webkit-border-radius: 5px 5px 0 0;
				-moz-border-radius: 5px 5px 0 0;
				-ms-border-radius: 5px 5px 0 0;
				-o-border-radius: 5px 5px 0 0;
				border-radius: 5px 5px 0 0;
			}
			> * {
				&:not(ul) {
					a {
						-webkit-border-radius: 5px 5px 0 0;
						-moz-border-radius: 5px 5px 0 0;
						-ms-border-radius: 5px 5px 0 0;
						-o-border-radius: 5px 5px 0 0;
						border-radius: 5px 5px 0 0;
					}
				}
			}
			border-top: 0;
		}
		&:last-child {
			>a {
				-webkit-border-radius: 0 0 5px 5px;
				-moz-border-radius: 0 0 5px 5px;
				-ms-border-radius: 0 0 5px 5px;
				-o-border-radius: 0 0 5px 5px;
				border-radius: 0 0 5px 5px;
			}
			>* {
				&:not(ul) {
					a {
						-webkit-border-radius: 0 0 5px 5px;
						-moz-border-radius: 0 0 5px 5px;
						-ms-border-radius: 0 0 5px 5px;
						-o-border-radius: 0 0 5px 5px;
						border-radius: 0 0 5px 5px;
					}
					a.highlighted {
						-webkit-border-radius: 0;
						-moz-border-radius: 0;
						-ms-border-radius: 0;
						-o-border-radius: 0;
						border-radius: 0;
					}
				}
			}
			>ul {
				-webkit-border-radius: 0 0 5px 5px;
				-moz-border-radius: 0 0 5px 5px;
				-ms-border-radius: 0 0 5px 5px;
				-o-border-radius: 0 0 5px 5px;
				border-radius: 0 0 5px 5px;
				>li {
					&:last-child {
						>a {
							-webkit-border-radius: 0 0 5px 5px;
							-moz-border-radius: 0 0 5px 5px;
							-ms-border-radius: 0 0 5px 5px;
							-o-border-radius: 0 0 5px 5px;
							border-radius: 0 0 5px 5px;
						}
						>* {
							&:not(ul) {
								a {
									-webkit-border-radius: 0 0 5px 5px;
									-moz-border-radius: 0 0 5px 5px;
									-ms-border-radius: 0 0 5px 5px;
									-o-border-radius: 0 0 5px 5px;
									border-radius: 0 0 5px 5px;
								}
								a.highlighted {
									-webkit-border-radius: 0;
									-moz-border-radius: 0;
									-ms-border-radius: 0;
									-o-border-radius: 0;
									border-radius: 0;
								}
							}
						}
						>ul {
							-webkit-border-radius: 0 0 5px 5px;
							-moz-border-radius: 0 0 5px 5px;
							-ms-border-radius: 0 0 5px 5px;
							-o-border-radius: 0 0 5px 5px;
							border-radius: 0 0 5px 5px;
							>li {
								&:last-child {
									>a {
										-webkit-border-radius: 0 0 5px 5px;
										-moz-border-radius: 0 0 5px 5px;
										-ms-border-radius: 0 0 5px 5px;
										-o-border-radius: 0 0 5px 5px;
										border-radius: 0 0 5px 5px;
									}
									>* {
										&:not(ul) {
											a {
												-webkit-border-radius: 0 0 5px 5px;
												-moz-border-radius: 0 0 5px 5px;
												-ms-border-radius: 0 0 5px 5px;
												-o-border-radius: 0 0 5px 5px;
												border-radius: 0 0 5px 5px;
											}
											a.highlighted {
												-webkit-border-radius: 0;
												-moz-border-radius: 0;
												-ms-border-radius: 0;
												-o-border-radius: 0;
												border-radius: 0;
											}
										}
									}
									>ul {
										-webkit-border-radius: 0 0 5px 5px;
										-moz-border-radius: 0 0 5px 5px;
										-ms-border-radius: 0 0 5px 5px;
										-o-border-radius: 0 0 5px 5px;
										border-radius: 0 0 5px 5px;
										>li {
											&:last-child {
												>a {
													-webkit-border-radius: 0 0 5px 5px;
													-moz-border-radius: 0 0 5px 5px;
													-ms-border-radius: 0 0 5px 5px;
													-o-border-radius: 0 0 5px 5px;
													border-radius: 0 0 5px 5px;
												}
												>* {
													&:not(ul) {
														a {
															-webkit-border-radius: 0 0 5px 5px;
															-moz-border-radius: 0 0 5px 5px;
															-ms-border-radius: 0 0 5px 5px;
															-o-border-radius: 0 0 5px 5px;
															border-radius: 0 0 5px 5px;
														}
														a.highlighted {
															-webkit-border-radius: 0;
															-moz-border-radius: 0;
															-ms-border-radius: 0;
															-o-border-radius: 0;
															border-radius: 0;
														}
													}
												}
												>ul {
													-webkit-border-radius: 0 0 5px 5px;
													-moz-border-radius: 0 0 5px 5px;
													-ms-border-radius: 0 0 5px 5px;
													-o-border-radius: 0 0 5px 5px;
													border-radius: 0 0 5px 5px;
													>li {
														&:last-child {
															>a {
																-webkit-border-radius: 0 0 5px 5px;
																-moz-border-radius: 0 0 5px 5px;
																-ms-border-radius: 0 0 5px 5px;
																-o-border-radius: 0 0 5px 5px;
																border-radius: 0 0 5px 5px;
															}
															>* {
																&:not(ul) {
																	a {
																		-webkit-border-radius: 0 0 5px 5px;
																		-moz-border-radius: 0 0 5px 5px;
																		-ms-border-radius: 0 0 5px 5px;
																		-o-border-radius: 0 0 5px 5px;
																		border-radius: 0 0 5px 5px;
																	}
																	a.highlighted {
																		-webkit-border-radius: 0;
																		-moz-border-radius: 0;
																		-ms-border-radius: 0;
																		-o-border-radius: 0;
																		border-radius: 0;
																	}
																}
															}
															>ul {
																-webkit-border-radius: 0 0 5px 5px;
																-moz-border-radius: 0 0 5px 5px;
																-ms-border-radius: 0 0 5px 5px;
																-o-border-radius: 0 0 5px 5px;
																border-radius: 0 0 5px 5px;
															}
															>a.highlighted {
																-webkit-border-radius: 0;
																-moz-border-radius: 0;
																-ms-border-radius: 0;
																-o-border-radius: 0;
																border-radius: 0;
															}
														}
													}
												}
												>a.highlighted {
													-webkit-border-radius: 0;
													-moz-border-radius: 0;
													-ms-border-radius: 0;
													-o-border-radius: 0;
													border-radius: 0;
												}
											}
										}
									}
									>a.highlighted {
										-webkit-border-radius: 0;
										-moz-border-radius: 0;
										-ms-border-radius: 0;
										-o-border-radius: 0;
										border-radius: 0;
									}
								}
							}
						}
						>a.highlighted {
							-webkit-border-radius: 0;
							-moz-border-radius: 0;
							-ms-border-radius: 0;
							-o-border-radius: 0;
							border-radius: 0;
						}
					}
				}
			}
			>a.highlighted {
				-webkit-border-radius: 0;
				-moz-border-radius: 0;
				-ms-border-radius: 0;
				-o-border-radius: 0;
				border-radius: 0;
			}
		}
	}
	ul {
		ul {
			a {
				border-left: 16px solid transparent;
				&:hover {
					border-left: 16px solid transparent;
				}
				&:focus {
					border-left: 16px solid transparent;
				}
				&:active {
					border-left: 16px solid transparent;
				}
			}
			ul {
				a {
					border-left: 24px solid transparent;
					&:hover {
						border-left: 24px solid transparent;
					}
					&:focus {
						border-left: 24px solid transparent;
					}
					&:active {
						border-left: 24px solid transparent;
					}
				}
				ul {
					a {
						border-left: 32px solid transparent;
						&:hover {
							border-left: 32px solid transparent;
						}
						&:focus {
							border-left: 32px solid transparent;
						}
						&:active {
							border-left: 32px solid transparent;
						}
					}
					ul {
						a {
							border-left: 40px solid transparent;
							&:hover {
								border-left: 40px solid transparent;
							}
							&:focus {
								border-left: 40px solid transparent;
							}
							&:active {
								border-left: 40px solid transparent;
							}
						}
					}
				}
			}
		}
	}
	li {
		a.current {
			color: $main_color;
		}
	}
	.current_page_item {
		a {
			color: $main_color;
		}
	}
}
.main-menu {
	float: none;
	max-width: 200px;
	width: 100%;
	position: relative;
	z-index: 9999;
	ul {
		width: 12em;
	}
}
*::selection {
	background-color: $background_color_7;
}
.body-wrapper {
	margin: 30px;
	background-color: $background_color_6;
	position: relative;
}
.doc-loader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 99999;
	background-color: $background_color_6;
}
.animate {
	opacity: 0;
	transition: .3s ease-in;
	-ms-transform: translate(0px,70px);
	-webkit-transform: translate(0px,70px);
	transform: translate(0px,70px);
}
.animate.show-it {
	opacity: 1;
	-ms-transform: translate(0px,0px);
	-webkit-transform: translate(0px,0px);
	transform: translate(0px,0px);
}
.wait-03s {
	-webkit-transition-delay: 0.3s;
	transition-delay: 0.3s;
}
.content-1140 {
	width: 1140px;
	img {
		max-width: 100%;
		max-height: 100%;
		width: auto;
		height: auto;
	}
}
.content-970 {
	width: 970px;
	img {
		max-width: 100%;
		max-height: 100%;
		width: auto;
		height: auto;
	}
}
.content-945 {
	width: 945px;
	img {
		max-width: 100%;
		max-height: 100%;
		width: auto;
		height: auto;
	}
}
.content-750 {
	width: 750px;
	img {
		max-width: 100%;
		max-height: 100%;
		width: auto;
		height: auto;
	}
}
.header-holder {
	width: 1140px;
	transition: margin-top 0.7s ease;
}
.header-holder.down {
	margin-top: 70px;
}
.site-logo {
	padding: 0;
}
.header-logo {
	padding: 80px 0;
	img {
		width: 130px;
		transition: opacity 0.3s linear;
		&:hover {
			opacity: 0.8;
		}
	}
	.svg-convert  {
		path {
			fill: $main_color;
		}
	}
}
.toggle-holder {
	width: 20px;
	height: 21px;
	top: 104px;
	right: 0;
	z-index: 1;
}
#toggle {
	&:hover {
		cursor: pointer;
		.second-menu-line {
			-webkit-transform: translate3d(0,0,0);
			transform: translate3d(0,0,0);
		}
	}
	div {
		height: 3px;
		margin-bottom: 6px;
		background-color: $background_color_8;
	}
	.second-menu-line {
		-webkit-transition: -webkit-transform 0.3s ease;
		transition: transform 0.3s ease;
		-webkit-transform: translate3d(10px,0,0);
		transform: translate3d(10px,0,0);
	}
	.third-menu-line {
		margin-bottom: 0;
	}
}
.menu-wraper {
	display: block;
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: $background_color_8;
	top: 0;
	left: 0;
	z-index: 99;
	overflow: auto;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s linear 0.5s, opacity 0.7s ease;
	cursor: url(../images/close.png), auto;
}
.menu-wraper.show {
	visibility: visible;
	opacity: 1;
	transition-delay: 0s;
}
.menu-wraper.center-relative {
	&::-webkit-scrollbar {
		width: 0 !important;
		height: 0 !important;
	}
}
.menu-holder {
	color: $color_10;
	position: relative;
	width: 1270px;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	opacity: 0;
	margin-top: 140px;
	transition: opacity 0.7s ease, margin-top 0.7s ease;
}
.menu-holder.show {
	opacity: 1;
	margin-top: 70px;
	transition-delay: 0.3s;
}
.menu-left-part {
	float: left;
	cursor: auto;
	width: 50%;
	&:after {
		content: "";
		position: absolute;
		display: block;
		border-right: 1px solid #1a1a1a;
		height: 110%;
		left: 50%;
		left: calc(50% - 1px);
		top: 0;
	}
}
.menu-left-text {
	font-family: $font_family_3;
	font-weight: 400;
	text-align: right;
	padding-right: 100px;
	font-size: 47px;
	line-height: 60px;
	padding-top: 50px;
	a {
		color: $color_10;
		border-bottom: 2px solid;
		display: inline-block;
	}
	cite {
		font-size: 2rem;
		line-height: 2.5rem;
		font-style: normal;
		display: inline-block;
		margin-top: 1rem;
	}
}
.menu-right-part {
	float: right;
	width: 50%;
}
#header-main-menu {
	font-weight: 300;
	text-align: left;
	padding-left: 100px;
	margin-top: 60px;
	display: inline-block;
	cursor: auto;
	.search-form {
		max-width: 350px;
	}
	.search-field {
		font-family: $font_family_2;
		max-width: 350px;
		width: 90%;
		color: $color_10;
		font-weight: 300;
		font-size: 24px;
		line-height: 24px;
		background-color: $background_color_2;
		border: none;
		border-bottom: 2px solid #000;
		padding-bottom: 5px;
		text-align: left;
		padding-top: 8px;
		transition: border 0.4s ease;
		-webkit-appearance: none;
		&::-ms-clear {
			display: none;
			width: 0;
			height: 0;
		}
		&::-ms-reveal {
			display: none;
			width: 0;
			height: 0;
		}
		&::-webkit-search-decoration {
			display: none;
		}
		&::-webkit-search-cancel-button {
			display: none;
		}
		&::-webkit-search-results-button {
			display: none;
		}
		&::-webkit-search-results-decoration {
			display: none;
		}
		&:focus {
			border-bottom: 2px solid #fff;
		}
		&::-webkit-input-placeholder {
			font-family: $font_family_2;
			font-weight: 300;
			color: $color_10;
			font-size: 24px;
			line-height: 24px;
			opacity: 1;
			text-align: left;
		}
		&::-moz-placeholder {
			font-family: $font_family_2;
			font-weight: 300;
			color: $color_10;
			font-size: 24px;
			line-height: 24px;
			opacity: 1;
			text-align: left;
		}
		&:-ms-input-placeholder {
			font-family: $font_family_2;
			font-weight: 300;
			color: $color_10;
			font-size: 24px;
			line-height: 24px;
			opacity: 1;
			text-align: left;
		}
		&:-moz-placeholder {
			font-family: $font_family_2;
			font-weight: 300;
			color: $color_10;
			font-size: 24px;
			line-height: 24px;
			opacity: 1;
			text-align: left;
		}
	}
}
.main-menu.sm-clean {
	background-color: $background_color_2;
	a {
		font-size: 24px;
		line-height: 24px;
		color: $color_10;
		&:hover {
			color: $main_color;
		}
	}
	.sub-menu {
		a {
			font-size: 18px;
			line-height: 18px;
			border-left: 0;
		}
	}
	.children {
		a {
			font-size: 18px;
			line-height: 18px;
			border-left: 0;
		}
	}
}
.widget_search {
	.search-field {
		font-family: $font_family_2;
		max-width: 350px;
		width: 90%;
		color: $color_10;
		font-weight: 300;
		font-size: 24px;
		line-height: 24px;
		background-color: $background_color_2;
		border: none;
		border-bottom: 2px solid #000;
		padding-bottom: 5px;
		text-align: left;
		padding-top: 8px;
		transition: border 0.4s ease;
		-webkit-appearance: none;
		text-align: center;
		&::-ms-clear {
			display: none;
			width: 0;
			height: 0;
		}
		&::-ms-reveal {
			display: none;
			width: 0;
			height: 0;
		}
		&::-webkit-search-decoration {
			display: none;
		}
		&::-webkit-search-cancel-button {
			display: none;
		}
		&::-webkit-search-results-button {
			display: none;
		}
		&::-webkit-search-results-decoration {
			display: none;
		}
		&:focus {
			border-bottom: 2px solid #fff;
		}
		&::-webkit-input-placeholder {
			font-family: $font_family_2;
			font-weight: 300;
			color: $color_10;
			font-size: 24px;
			line-height: 24px;
			opacity: 1;
			text-align: left;
			text-align: center;
		}
		&::-moz-placeholder {
			font-family: $font_family_2;
			font-weight: 300;
			color: $color_10;
			font-size: 24px;
			line-height: 24px;
			opacity: 1;
			text-align: left;
			text-align: center;
		}
		&:-ms-input-placeholder {
			font-family: $font_family_2;
			font-weight: 300;
			color: $color_10;
			font-size: 24px;
			line-height: 24px;
			opacity: 1;
			text-align: left;
			text-align: center;
		}
		&:-moz-placeholder {
			font-family: $font_family_2;
			font-weight: 300;
			color: $color_10;
			font-size: 24px;
			line-height: 24px;
			opacity: 1;
			text-align: left;
			text-align: center;
		}
	}
}
.menu-portfolio-category {
	padding-left: 100px;
	padding-top: 50px;
	cursor: auto;
	max-width: 300px;
}
.portfolio-category {
	a {
		font-family: $font_family_1;
		font-weight: 400;
		line-height: 100%;
		font-size: 10px;
		color: $color_10;
		letter-spacing: 2px;
		padding-left: 2px;
		text-transform: uppercase;
		margin-right: 20px;
		display: inline-block;
		&:last-child {
			margin-right: 0;
		}
	}
}
.blog-item-holder {
	margin: 100px 0;
	text-align: center;
	&:first-of-type {
		margin-top: 0;
	}
	.post-thumbnail {
		width: 555px;
		max-width: 45%;
		overflow: hidden;
		line-height: 0;
		transition: all 0.3s ease;
		
		a {
			// filter: sepia(1);
			transition: all 0.3s ease;
			img {
				
			}
			&:hover {
				// opacity: 0.8;
			
    			// filter: sepia(0);
			}
		}
		
	}
	h2.entry-title {
		display: block;
		font-family: $font_family_3;
		font-weight: 400;
		font-size: 44px;
		line-height: 44px;
		&:hover {
			&+.read-more-arrow {
				img {
					transform: translate(10px);
				}
			}
		}
		cite {
			display: block;
			width: 90%;
			margin-top: 1rem;
			font-size: 2rem;
			line-height: 2.5rem;
			font-style: normal;

		}
	}
}
.blog-item-holder.has-post-thumbnail {
	&:nth-of-type(2n+1) {
		.post-thumbnail {
			float: left;
			text-align: right;
			margin-right: 80px;
		}
		.entry-holder {
			float: left;
		}
	}
	&:nth-of-type(2n) {
		.post-thumbnail {
			float: right;
			text-align: left;
			margin-left: 80px;
		}
		.entry-holder {
			float: right;
		}
	}
	.entry-holder {
		width: 440px;
		max-width: 45%;
		text-align: left;
	}
}
.post-num {
	font-family: $font_family_3;
	font-weight: 400;
	font-size: 100px;
	line-height: 100%;
	margin-top: -30px;
	span {
		letter-spacing: -10px;
	}
	.separator {
		color: $color_11;
		vertical-align: -20px;
	}
	.total-posts {
		color: $color_11;
		vertical-align: -40px;
		margin-left: -10px;
	}
}
.entry-info {
	font-family: $font_family_1;
	font-weight: 700;
	font-size: 11px;
	letter-spacing: 2px;
	line-height: 22px;
	text-transform: uppercase;
	padding-top: 40px;
	padding-bottom: 50px;
	ul {
		padding: 0;
		margin: 0;
	}
	.cat-links {
		li {
			display: inline-block;
			&:after {
				content: ",";
				padding-right: 5px;
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
}
.entry-info-left {
	display: inline-block;
	max-width: 180px;
	padding-right: 40px;
	text-align: left;
}
.entry-info-right {
	display: inline-block;
	vertical-align: top;
	text-align: left;
}
.read-more-arrow {
	img {
		padding-top: 20px;
		transition: transform .3s ease;
		width: 50px;
		height: 20px;
		&:hover {
			transform: translate(10px);
		}
	}
}
.load-more-posts {
	margin-top: 50px;
	transition: margin .3s linear;
}
.more-posts-index {
	position: relative;
	display: inline-block;
	cursor: pointer;
	border-bottom: 2px solid;
	padding: 10px 30px;
	color: $color_1 !important;
	&:before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 0;
		border-left: 2px solid;
		border-right: 2px solid;
		-webkit-transition: all ease 0.3s 0.15s;
		transition: all ease 0.3s 0.15s;
	}
	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 0;
		height: 100%;
		border-top: 2px solid;
		-webkit-transition: all ease 0.3s;
		transition: all ease 0.3s;
	}
	&:hover {
		&:before {
			height: 100%;
			-webkit-transition: all ease 0.3s;
			transition: all ease 0.3s;
		}
		&:after {
			left: 0;
			width: 100%;
			-webkit-transition: all ease 0.3s 0.15s;
			transition: all ease 0.3s 0.15s;
		}
	}
}
.page {
	.site-content {
		color: $color_12;
	}
	h1.entry-title {
		font-family: $font_family_3;
		font-size: 47px;
		line-height: 60px;
		font-weight: 400;
		max-width: 650px;
		margin: 0 auto;
		padding-top: 0;
		padding-bottom: 35px;
		text-align: center;
		a {
			position: relative;
			display: inline-block;
			color: $main_color;
			&:after {
				content: '';
				display: block;
				width: 0;
				height: 3px;
				background-color: $background_main_color;
				transition: width .3s;
				position: absolute;
				left: 0;
				bottom: 0;
			}
			&:hover {
				&:after {
					width: 100%;
				}
			}
		}
	}
}
.page-desc {
	font-family: $font_family_1;
	font-weight: 700;
	font-size: 11px;
	line-height: 15px;
	text-align: center;
	letter-spacing: 1px;
}
.info-code {
	padding: 10px 0;
}
.info-code-title {
	font-size: 15px;
	line-height: 15px;
	font-style: italic;
	color: $color_13;
	font-weight: 400;
}
.info-code-content {
	font-family: $font_family_1;
	font-weight: 700;
	font-size: 11px;
	line-height: 18px;
	letter-spacing: 2px;
}
.testimonial-slider-holder {
	font-size: 24px;
	line-height: 43px;
	max-width: 650px;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 100px;
	.slick-prev {
		left: -65px;
	}
	.slick-next {
		right: -65px;
	}
}
.progress_bar {
	margin-bottom: 35px;
}
.progress_bar_field_holder {
	height: 2px;
	position: relative;
	width: 100%;
	vertical-align: middle;
	overflow: hidden;
	background-color: $background_color_9;
	max-width: 500px;
}
.progress_bar_title {
	font-family: $font_family_1;
	font-weight: 700;
	font-size: 11px;
	text-transform: uppercase;
	letter-spacing: 2px;
	line-height: 15px;
}
.progress_bar_field_perecent {
	height: 2px;
	position: absolute;
	top: 0;
	left: 0;
	background-color: $background_color_8;
}
.single {
	.site-content {
		color: $color_12;
	}
	h1.entry-title {
		font-family: $font_family_3;
		font-weight: 400;
		color: $color_1;
		font-size: 60px;
		line-height: 60px;
		margin-bottom: 40px;
		-ms-word-wrap: break-word;
		word-wrap: break-word;
		max-width: 580px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}
	.entry-info {
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		color: $color_1;
	}
	.post-num {
		text-align: center;
		margin-bottom: 100px;
		.current-post {
			color: $color_1;
		}
	}
	.wp-link-pages {
		span {
			color: $color_15;
		}
		color: $main_color;
	}
	.nav-links {
		margin-top: 70px;
		margin-bottom: 70px;
		position: relative;
		&:before {
			content: "";
			display: block;
			position: absolute;
			width: 2px;
			height: 100%;
			background-color: $background_color_8;
			left: 50%;
			margin-left: -2px;
		}
	}
	.nav-previous {
		display: inline-block;
		max-width: 290px;
		text-align: right;
		float: left;
	}
	.nav-next {
		display: inline-block;
		float: right;
		max-width: 290px;
		text-align: left;
	}
}
.single-post {
	.entry-content {
		font-weight: 300;
	}
	.nav-previous {
		a {
			float: right;
		}
	}
	.nav-next {
		a {
			float: left;
		}
	}
}
.tags-holder {
	font-family: $font_family_1;
	margin: 50px 0;
	display: inline-block;
	a {
		color: $color_14;
		border: 1px solid;
		text-decoration: none;
		margin-right: 20px;
		margin-top: 10px;
		margin-bottom: 10px;
		display: inline-block;
		border-radius: 50px;
		padding: 5px 20px;
		font-size: 13px;
		&:last-of-type {
			margin-right: 0;
		}
	}
}
.nav-links {
	p {
		font-family: $font_family_1;
		font-weight: 700;
		font-size: 11px;
	}
}
.nav-previous {
	a {
		font-family: $font_family_3;
		font-weight: 400;
		font-size: 30px;
		line-height: 35px;
	}
}
.nav-next {
	a {
		font-family: $font_family_3;
		font-weight: 400;
		font-size: 30px;
		line-height: 35px;
	}
}
.archive-title {
	h1 {
		font-family: $font_family_3;
		font-weight: 400;
		border-bottom: 2px solid;
		display: inline-block;
	}
}
.page-pagination-holder {
	margin: 70px 0;
}
.page-numbers.current {
	border: 1px solid #e6b02f;
	color: $main_color;
	padding: 7px 12px;
	border-radius: 90%;
}
.page-numbers {
	margin: 0 5px;
	font-size: 16px;
	&:hover {
		color: $main_color;
	}
}
.search {
	.search-title {
		h1 {
			font-family: $font_family_3;
			font-weight: 400;
			border-bottom: 2px solid;
			display: inline-block;
		}
	}
	.blog-item-holder {
		padding: 0;
		margin: 0;
		.entry-title {
			a {
				font-size: 35px;
				line-height: 40px;
			}
		}
	}
}
.header-content {
	padding-bottom: 100px;
	text-align: center;
}
.grid-item.quote-item {
	font-family: $font_family_3;
	width: 400px;
	font-size: 40px;
	line-height: 60px;
	text-align: center;
	&:before {
		font-family: $font_family_1;
		font-weight: 700;
		display: block;
		content: "\201C";
		font-size: 470px;
		line-height: 100%;
		color: $main_color;
		margin-bottom: -346px;
		margin-top: -50px;
	}
}
.grid {
	width: 1300px;
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	text-align: center;
	list-style: none;
	max-width: 100%;
	padding-left: 0;
}
.item-wrapper {
	display: inline-block;
	position: relative;
}
.grid-item {
	display: block;
	vertical-align: middle;
	font-size: 0;
	line-height: 0;
	margin: 3%;
	max-width: 43%;
	img {
		display: block;
		width: auto;
		height: auto;
		max-height: 100%;
		max-width: 100%;
	}
	&:hover {
		.portfolio-text-holder {
			opacity: 1;
		}
	}
	.portfolio-arrow {
		img {
			margin-left: auto;
			margin-right: auto;
			width: 35px;
			height: 20px;
			margin-top: 30px;
			transition: transform .3s ease;
			&:hover {
				transform: translate(10px);
			}
		}
	}
}
.grid-item.hidden {
	display: none !important;
}
.grid-item.loaded {
	display: inline-block !important;
}
.portfolio-text-holder {
	position: absolute;
	top: 30px;
	left: 30px;
	bottom: 30px;
	right: 30px;
	font-size: 20px;
	background-color: $background_color_11;
	text-align: center;
	opacity: 0;
	transition: opacity 0.3s ease-out;
	.portfolio-text {
		font-family: $font_family_3;
		font-size: 36px;
		line-height: 100%;
		&:hover {
			&+p {
				&+.portfolio-arrow {
					a {
						img {
							transform: translate(10px);
						}
					}
				}
			}
		}
	}
	.portfolio-category {
		padding-top: 20px;
		a {
			font-weight: 700;
			color: $main_color;
			margin-right: 0;
			&:after {
				content: "/";
				padding: 0 5px;
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
}
.more-posts-portfolio, .fazer-marcacao {
	position: relative;
	display: inline-block;
	cursor: pointer;
	border-bottom: 2px solid;
	padding: 10px 30px;
	color: $color_1 !important;
	&:before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 0;
		border-left: 2px solid;
		border-right: 2px solid;
		-webkit-transition: all ease 0.3s 0.15s;
		transition: all ease 0.3s 0.15s;
	}
	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 0;
		height: 100%;
		border-top: 2px solid;
		-webkit-transition: all ease 0.3s;
		transition: all ease 0.3s;
	}
	&:hover {
		&:before {
			height: 100%;
			-webkit-transition: all ease 0.3s;
			transition: all ease 0.3s;
		}
		&:after {
			left: 0;
			width: 100%;
			-webkit-transition: all ease 0.3s 0.15s;
			transition: all ease 0.3s 0.15s;
		}
	}
}
.fazer-marcacao 
{
	&:hover {
		// color: $main_color !important;
	}
}
.load-more-portfolio {
	margin-top: 50px;
}
.single-portfolio {
	.top-content {
		text-align: center;
		padding-bottom: 40px;
		.slick-dotted.slick-slider {
			margin-bottom: 75px;
		}
	}
	.portfolio-item-info {
		width: 180px;
		float: left;
	}
	.content-wrapper {
		width: 580px;
		float: right;
	}
	.nav-previous {
		float: left;
		a {
			img {
				transition: transform .3s ease;
				width: 35px;
				height: 20px;
				&:hover {
					transform: translate(-10px);
				}
			}
		}
	}
	.nav-next {
		float: left;
		margin-left: 30px;
		a {
			img {
				transition: transform .3s ease;
				width: 35px;
				height: 20px;
				&:hover {
					transform: translate(10px);
				}
			}
		}
	}
}
.portfolio-nav {
	padding-top: 30px;
}
.thumb-header {
	margin-top: -120px;
}
.single-gallery {
	h1.entry-title {
		font-family: $font_family_3;
		font-weight: 400;
		font-size: 60px;
		line-height: 60px;
		-ms-word-wrap: break-word;
		word-wrap: break-word;
		max-width: 580px;
	}
	.entry-content {
		padding-top: 50px;
	}
}
.big-text {
	font-family: $font_family_3;
	font-size: 46px;
	line-height: 50px;
	color: $color_1;
	padding: 0;
	font-weight: 400;
}
.contact-form {
	max-width: 100%;
	width: 770px;
	margin: 0 auto;
	font-size: 22px;
	color: $color_12;
	input[type=text] {
		color: $color_12;
		border: 0;
		border-bottom: 1px solid;
		height: 28px;
		font-size: 18px;
		line-height: 25px;
		font-family: $font_family_2;
		font-weight: 300;
		width: 100%;
		padding: 0;
		margin-bottom: 40px;
		padding-bottom: 5px;
		&::-webkit-input-placeholder {
			font-family: $font_family_2;
			color: $color_12;
			font-weight: 300;
			font-size: 18px;
			line-height: 25px;
		}
		&::-moz-placeholder {
			font-family: $font_family_2;
			color: $color_12;
			font-weight: 300;
			font-size: 18px;
			line-height: 25px;
			opacity: 1;
		}
		&:-ms-input-placeholder {
			font-family: $font_family_2;
			color: $color_12;
			font-weight: 300;
			font-size: 18px;
			line-height: 25px;
		}
		&:-moz-placeholder {
			font-family: $font_family_2;
			color: $color_12;
			font-weight: 300;
			font-size: 18px;
			line-height: 25px;
			opacity: 1;
		}
	}
	input[type=email] {
		color: $color_12;
		border: 0;
		border-bottom: 1px solid;
		height: 28px;
		font-size: 18px;
		line-height: 25px;
		font-family: $font_family_2;
		font-weight: 300;
		width: 100%;
		padding: 0;
		margin-bottom: 40px;
		padding-bottom: 5px;
		&::-webkit-input-placeholder {
			font-family: $font_family_2;
			color: $color_12;
			font-weight: 300;
			font-size: 18px;
			line-height: 25px;
		}
		&::-moz-placeholder {
			font-family: $font_family_2;
			color: $color_12;
			font-weight: 300;
			font-size: 18px;
			line-height: 25px;
			opacity: 1;
		}
		&:-ms-input-placeholder {
			font-family: $font_family_2;
			color: $color_12;
			font-weight: 300;
			font-size: 18px;
			line-height: 25px;
		}
		&:-moz-placeholder {
			font-family: $font_family_2;
			color: $color_12;
			font-weight: 300;
			font-size: 18px;
			line-height: 25px;
			opacity: 1;
		}
	}
	textarea {
		color: $color_12;
		border: 0;
		border-bottom: 1px solid;
		height: 28px;
		font-size: 18px;
		line-height: 25px;
		font-family: $font_family_2;
		font-weight: 300;
		width: 100%;
		padding: 0;
		margin-bottom: 40px;
		-webkit-transition: all 0.3s linear;
		transition: all 0.3s linear;
		padding-top: 8px;
		overflow: hidden;
		&::-webkit-input-placeholder {
			font-family: $font_family_2;
			color: $color_12;
			font-weight: 300;
			font-size: 18px;
			line-height: 25px;
		}
		&::-moz-placeholder {
			font-family: $font_family_2;
			color: $color_12;
			font-weight: 300;
			font-size: 18px;
			line-height: 25px;
			opacity: 1;
		}
		&:-ms-input-placeholder {
			font-family: $font_family_2;
			color: $color_12;
			font-weight: 300;
			font-size: 18px;
			line-height: 25px;
		}
		&:-moz-placeholder {
			font-family: $font_family_2;
			color: $color_12;
			font-weight: 300;
			font-size: 18px;
			line-height: 25px;
			opacity: 1;
		}
		&:focus {
			height: 150px;
			overflow: auto;
		}
	}
	input[name="your-name"] {
		margin-top: 0;
		padding-top: 0;
	}
	input[type=submit] {
		text-align: center;
		padding: 10px 0px;
		cursor: pointer;
		background: transparent;
		font-family: $font_family_2;
		font-weight: 300;
		width: 100%;
		font-size: 18px;
		border: 0;
		border-bottom: 2px solid;
		position: relative;
		z-index: 3;
	}
}
.contact-submit-holder {
	position: relative;
	margin-bottom: 25px;
	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 0;
		height: 100%;
		border-top: 2px solid;
		-webkit-transition: all ease 0.3s;
		transition: all ease 0.3s;
	}
	&:before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 0;
		border-left: 2px solid;
		border-right: 2px solid;
		-webkit-transition: all ease 0.3s 0.15s;
		transition: all ease 0.3s 0.15s;
	}
	&:hover {
		&:before {
			height: 100%;
			-webkit-transition: all ease 0.3s;
			transition: all ease 0.3s;
		}
		&:after {
			left: 0;
			width: 100%;
			-webkit-transition: all ease 0.3s 0.15s;
			transition: all ease 0.3s 0.15s;
		}
	}
}
.footer {
	font-size: 16px;
	line-height: 36px;
	margin-top: 70px;
	padding-bottom: 110px;
	clear: both;
	color: $color_10;
	text-align: center;
	background: #000;
	a {
		color: $color_10;
	}
}
.footer-logo {
	padding-bottom: 130px;
	position: relative;
	width: 130px;
	height: 130px;
	margin: 0 auto;
	padding-top: 90px;
	&:after {
		content: "";
		display: block;
		height: 77px;
		width: 2px;
		background: #1a1a1a;
		position: absolute;
		left: 50%;
		margin-left: -2px;
		margin-top: 10px;
	}
	.svg-convert {
		path {
			fill: #fff;
		}
	}
}
.footer-text {
	padding-bottom: 50px;
}
.footer-first-line {
	font-family: $font_family_3;
	font-size: 47px;
	line-height: 60px;
	a {
		border-bottom: 2px solid;
	}
}
.footer-second-line {
	font-weight: 400;
	padding-top: 20px;
	a {
		border-bottom: 1px solid;
	}
}
.our-position-holder {
	font-family: $font_family_3;
	position: relative;
	font-size: 24px;
	// padding: 35px 0;
	// &:before {
	// 	content: "";
	// 	display: block;
	// 	height: 100%;
	// 	width: 2px;
	// 	background: #1a1a1a;
	// 	position: absolute;
	// 	left: 50%;
	// 	margin-left: -2px;
	// 	bottom: 0;
	// }
	&:after {
		content: "";
		display: block;
		height: 2px;
		width: 100%;
		position: absolute;
		background: #1a1a1a;
		bottom: 0;
	}
	&.about {
		&:after {
			height: 1px;
			background: $background_color_9;
			margin-bottom: 10px;
			margin-top: 10px;
			position: static;
		}
	}
}
.our-position-left {
	display: inline-block;
	padding-right: 50px;
}
.our-position-right {
	display: inline-block;
}
ul#footer-sidebar {
	li.widget {
		width: 33.3%;
		padding-right: 4%;
		float: left;
		box-sizing: border-box;
		&:nth-child(2n) {
			padding-right: 0;
			margin-bottom: 50px;
		}
	}
	&:after {
		clear: both;
		content: "";
		display: block;
	}
	padding: 30px 0;
	font-size: 14px;
	line-height: 28px;
	border-bottom: 2px solid #1a1a1a;
}
h4.widgettitle {
	font-size: 16px;
	font-family: $font_family_3;
}
.tagcloud {
	a {
		font-size: 14px !important;
		display: block;
		&.current {
			color: $main_color;
		}
	}
}
.widget_recent_entries {
	a {
		border-bottom: 1px solid;
	}
	span.post-date {
		display: block;
		font-size: 12px;
		line-height: 100%;
		margin-bottom: 20px;
	}
}
ul.copyright-holder {
	font-family: $font_family_1;
	font-weight: 400;
	font-size: 10px;
	&:after {
		content: "";
		display: block;
		clear: both;
	}
}
li.copyright-footer {
	float: left;
	text-align: left;
	width: 50%;
}
li.social-footer {
	float: right;
	text-align: right;
	width: 50%;
	a {
		padding-left: 20px;
		&:first-child {
			padding-left: 0;
		}
	}
}
@media screen and (max-width: 1600px) {
	.content-1140 {
		max-width: 95%;
	}
	.menu-right-part {
		width: 45%;
	}
	.menu-left-part {
		margin-left: 5%;
		&:after {
			left: 55%;
			left: calc(55% - 1px);
		}
	}
}
@media screen and (max-width: 1450px) {
	.one_half {
		margin-right: 8%;
		float: left;
		position: relative;
		margin-bottom: 37px;
		width: 46%;
	}
	.one_third {
		margin-right: 8%;
		float: left;
		position: relative;
		margin-bottom: 37px;
		width: 28%;
	}
	.two_third {
		margin-right: 8%;
		float: left;
		position: relative;
		margin-bottom: 37px;
		width: 64%;
	}
	.three_fourth {
		margin-right: 8%;
		float: left;
		position: relative;
		margin-bottom: 37px;
		width: 73%;
	}
	.one_fourth {
		margin-right: 8%;
		float: left;
		position: relative;
		margin-bottom: 37px;
		width: 19%;
	}
	blockquote.inline-blockquote {
		display: block;
		margin: 50px 0;
		width: auto;
	}
}
@media screen and (max-width: 1220px) {
	blockquote {
		font-size: 17px;
		line-height: 28px;
		text-align: center;
		&:before {
			line-height: 150px;
			font-size: 150px;
			position: relative;
			top: 0;
			left: 0;
			text-align: center;
			display: block;
			margin-bottom: -60px;
		}
	}
	.menu-left-text {
		font-size: 35px;
		line-height: 50px;
	}
}
@media screen and (max-width: 1020px) {
	.header-logo {
		padding: 50px 0;
	}
	.toggle-holder {
		top: 71px;
		right: 40px;
	}
	.page {
		h1.entry-title {
			font-size: 40px;
			line-height: 45px;
			max-width: 95%;
		}
	}
	.single {
		h1.entry-title {
			font-size: 40px;
			line-height: 44px;
			margin-bottom: 0;
			max-width: 95%;
		}
		.post-num {
			margin-bottom: 50px;
		}
	}
	.content-970 {
		width: 95%;
	}
	.content-945 {
		width: 95%;
	}
	.content-750 {
		width: 95%;
	}
	.menu-left-part {
		display: none;
	}
	#header-main-menu {
		text-align: center;
		padding-left: 0;
		margin-top: 30px;
		.search-field {
			text-align: center;
			&::-webkit-input-placeholder {
				text-align: center;
			}
			&::-moz-placeholder {
				text-align: center;
			}
			&:-ms-input-placeholder {
				text-align: center;
			}
			&:-moz-placeholder {
				text-align: center;
			}
		}
	}
	.menu-right-part {
		float: none;
		width: 100%;
		margin: 0 auto;
		text-align: center;
	}
	.main-menu {
		max-width: 100%;
	}
	.menu-portfolio-category {
		padding-left: 0;
		margin: 0 auto;
	}
	.one_half {
		width: 100%;
		float: none;
		margin-right: 0;
		margin-left: 0;
		text-align: center;
	}
	.one_third {
		width: 100%;
		float: none;
		margin-right: 0;
		margin-left: 0;
		text-align: center;
	}
	.one_fourth {
		width: 100%;
		float: none;
		margin-right: 0;
		margin-left: 0;
		text-align: center;
	}
	.two_third {
		width: 100%;
		float: none;
		margin-right: 0;
		margin-left: 0;
		text-align: center;
	}
	.three_fourth {
		width: 100%;
		float: none;
		margin-right: 0;
		margin-left: 0;
		text-align: center;
	}
	.progress_bar_field_holder {
		width: 100%;
		max-width: 100%;
	}
	.blog-item-holder {
		padding: 30px 0;
		margin: 30px 0;
		.post-thumbnail {
			float: none !important;
			text-align: center !important;
			margin-left: auto !important;
			margin-right: auto !important;
			margin-bottom: 30px;
			max-width: 100%;
			width: 100%;
		}
		h2.entry-title {
			font-size: 40px;
		}
	}
	.blog-item-holder.has-post-thumbnail {
		.entry-holder {
			float: none !important;
			text-align: center;
			margin-left: auto;
			margin-right: auto;
			max-width: 100%;
			width: 100%;
		}
	}
	.post-num {
		font-size: 65px;
		margin-top: -15px;
	}
	.entry-info {
		padding-bottom: 20px;
	}
	.slick-dotted.slick-slider {
		margin-bottom: 90px;
	}
	.info-code {
		padding: 10px 20px;
		display: inline-block;
		text-align: center;
	}
	.grid-item.quote-item {
		&:before {
			font-size: 260px;
			margin-bottom: -210px;
		}
		font-size: 30px;
		line-height: 45px;
	}
	.portfolio-text-holder {
		top: 10px;
		left: 10px;
		bottom: 10px;
		right: 10px;
	}
	.carousel-slider {
		.slick-slide {
			.item-text {
				a {
					font-size: 25px;
					line-height: 35px;
					padding-top: 20px;
					margin-top: 0 !important;
				}
				position: relative;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				opacity: 1 !important;
			}
		}
	}
	.single-portfolio {
		.entry-content {
			position: relative;
			padding-bottom: 50px;
		}
		.portfolio-item-info {
			width: auto;
			float: none;
			text-align: center;
			padding-bottom: 30px;
		}
		.content-wrapper {
			width: auto;
			float: none;
		}
	}
	.portfolio-nav {
		position: absolute;
		bottom: -35px;
		text-align: center;
		display: block;
		width: 100%;
		left: 0;
		.nav-previous {
			float: none;
		}
		.nav-next {
			float: none;
		}
	}
	.footer {
		margin-top: 40px;
		padding-bottom: 30px;
	}
	.footer-first-line {
		font-size: 35px;
		line-height: 35px;
	}
	.our-position-left {
		display: block;
		padding-right: 0;
	}
	.our-position-right {
		display: block;
	}
	.our-position-holder {
		&:before {
			display: none;
		}
		&:after {
			width: 50%;
			left: 25%;
		}
		&.about {
			&:after {
				width: 100%;
			}
		}
	}
	ul#footer-sidebar {
		li.widget {
			width: 100%;
			margin-right: 0;
			float: none;
			padding-bottom: 30px;
			&:nth-child(3n) {
				margin-bottom: 0;
			}
		}
	}
	li.copyright-footer {
		float: none;
		text-align: center;
		width: 100%;
	}
	li.social-footer {
		float: none;
		text-align: center;
		width: 100%;
	}
}
@media screen and (max-width: 805px) {
	p.custom-text-class {
		display: block !important;
	}
	p.custom-field-class {
		display: block !important;
		width: 100% !important;
	}
}
@media screen and (max-width: 750px) {
	.grid-item {
		display: block !important;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 33px;
		max-width: 95%;
	}
	.grid-item.quote-item {
		margin-top: 60px;
	}
	.single {
		.nav-links {
			&:before {
				display: none;
			}
		}
		.nav-previous {
			display: block;
			max-width: 95%;
			text-align: center;
			float: none;
			margin-left: auto;
			margin-right: auto;
			padding-bottom: 40px;
			a {
				float: none;
				font-size: 24px;
				line-height: 30px;
			}
		}
		.nav-next {
			display: block;
			max-width: 95%;
			text-align: center;
			float: none;
			margin-left: auto;
			margin-right: auto;
			a {
				float: none;
				font-size: 24px;
				line-height: 30px;
			}
		}
	}
	.single-portfolio {
		.nav-previous {
			display: inline-block;
			padding-bottom: 0;
			max-width: none;
			margin: 0 15px;
		}
		.nav-next {
			display: inline-block;
			padding-bottom: 0;
			max-width: none;
			margin: 0 15px;
		}
	}
	.carousel-slider {
		.slick-slide {
			img {
				max-height: 300px;
			}
		}
	}
}


.about {
	p {
		font-size: .95rem;
	}
}
