@charset 'utf-8';

// Config Helpers
@import 'theme/opta';

.load-more-portfolio, .footer-second-line, .portfolio-arrow {
	display: none;
}

.item-wrapper {
	a {
		// pointer-events: none;
		// cursor: default;
		display: block;
	}
}
.blog-item-holder.has-post-thumbnail .post-thumbnail {
	text-align: center !important;
}

.blog-item-holder.article-list-item {
	
	.post-thumbnail {
		a {
			// filter: sepia(0);
		}
		
	}
}

#content {
	.entry-title {
		margin-bottom: 0;
	}
	.entry-info {
		padding-top: 0;
		text-align: center;
	}
	.content-750 {
		&.center-relative {
			padding-bottom: 40px;
		}
	}
}
.contact-form {
	.error-field {
		color: #ab2121;
		font-size: .9rem;
		display: none;
	}
	input, textarea {
		&.is-invalid-input {
		color: #ab2121 !important;
		}
	}
	.success-field {
		font-size: .9rem;
		display: none;
	}
}

._hj-f5b2a1eb-9b07_widget._hj-f5b2a1eb-9b07_skin_light, ._hj-f5b2a1eb-9b07_widget._hj-f5b2a1eb-9b07_skin_light ._hj-f5b2a1eb-9b07_widget_open_close
{
	border: 1px solid #ddd;
	border-radius: 0;
	box-shadow: 0 0 0 0 !important;
	font-family: $font_family_1 !important;
}
._hj-f5b2a1eb-9b07_widget, ._hj-f5b2a1eb-9b07_widget * {
	font-family: $font_family_1 !important;
}

li.social-footer {
	line-height: 2rem;
	
}